import React from 'react';
import './index.css';
import Loading from '../../components/Loading';
import Profile from '../../components/Profile';
import { Button, FormGroup, Label, Input, Row, Col } from 'reactstrap';
import api from '../../api';

const default_user = {
  client_id: '',
  first_name: '',
  last_name: '',
  middle_name: '',
  user_groups: [],
}

export default class Users extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      users: null,
      isLoading: false,
      isAddUser: false,
      newUser: default_user,
      userGroups: null,
    }
  }
  componentDidMount = () => {
    document.title = "User management | TCH Maria";
    this.getUsers();
    this.getUserGroups();
  }
  toogleUserGroups(userGroup) {
    const { newUser } = this.state;
    if (!newUser.user_groups) {
      newUser.user_groups = [];
    }
    
    const existing_group_ids = newUser.user_groups.map(userGroup => userGroup.id);

    if (existing_group_ids.includes(userGroup.id)) {
      const index = existing_group_ids.indexOf(userGroup.id);
      if (index > -1) {
        newUser.user_groups.splice(index, 1);
      }
    } else {
      newUser.user_groups.push(userGroup);
    }
    
    this.setState({ newUser });
  }
  createNewUser() {
    const { newUser } = this.state;
    api.fetchAPI({
      method: newUser.id ? 'put' : 'post',
      uri: `users`,
      body: newUser,
      success: (response) => {
        if (response.status === 'ok') {
          this.setState({ 
            newUser: default_user,
          });
          this.getUsers();
        } else {
          alert(response.message);
        }
        this.setState({ isLoading: false, isAddUser: false });
      },
    });
  }
  getUsers() {
    this.setState({ isLoading: true, users: [] });
    api.fetchAPI({
      method: 'get',
      uri: `users`,
      success: (response) => {
        if (response.status === 'ok') {
          this.setState({ users: response.data.users, isLoading: false });
        }
      },
    });
  }
  getUserGroups() {
    this.setState({ isLoading: true, users: [] });
    api.fetchAPI({
      method: 'get',
      uri: `user_groups`,
      success: (response) => {
        if (response.status === 'ok') {
          this.setState({ userGroups: response.data.user_groups, isLoading: false });
        }
      },
    });
  }
  deactivateUser(user) {
    api.fetchAPI({
      method: 'delete',
      uri: `users`,
      body: user,
      success: (response) => {
        if (response.status === 'ok') {
          this.getUsers()
        }
        this.setState({ isLoading: false, isAddUser: false, });
      },
    });
  }
  editUserValue = ({ property, value }) => {
    const { newUser } = this.state;
    newUser[property] = value ? value : '';
    this.setState({ newUser });
  }
  render() {
    const { currentUser } = this.props;
    if (!currentUser) return null;
    const currentUserPermissions = currentUser.permission_names;
    const isCurrentUserAdmin = currentUser.is_system_admin;

    if (!currentUserPermissions.indexOf('can_create_user') && !isCurrentUserAdmin) return null;
    
    const { isLoading, users, isAddUser, newUser, userGroups } = this.state;
    if (!users) return null;

    let user_group_ids = [];
    if (newUser.user_groups) {
      user_group_ids = newUser.user_groups.map(userGroup => userGroup.id);
    }
    
    return (
      <div className="users__main">
        {!isAddUser &&
          <Button color="primary" onClick={() => this.setState({ isAddUser: true })}>Add new user</Button>
        }
        {isAddUser &&
          <div className="users__add-new">
            <FormGroup>
              <Input
                type="text"
                placeholder="Mã nhân viên"
                value={newUser.client_id}
                disabled={newUser.id ? true : false}
                onChange={(e) => this.editUserValue({ property: 'client_id', value: e.target.value })}
              />
            </FormGroup>
            <FormGroup>
              <Input
                type="text"
                placeholder="Tên"
                value={newUser.first_name}
                onChange={(e) => this.editUserValue({ property: 'first_name', value: e.target.value })}
              />
            </FormGroup>
            <FormGroup>
              <Input
                type="text"
                placeholder="Họ"
                value={newUser.last_name}
                onChange={(e) => this.editUserValue({ property: 'last_name', value: e.target.value })}
              />
            </FormGroup>
            {userGroups &&
              <div className="user_group_selection">
                <h4>User groups</h4>
                {userGroups.map(userGroup => 
                  <Button
                    className="user_group_item"
                    onClick={() => this.toogleUserGroups(userGroup)}
                    color={user_group_ids.includes(userGroup.id) ? 'info' : 'secondary'}
                  >
                    {userGroup.name}
                  </Button>
                )}
              </div>
            }
            <FormGroup>
              <Button color="success" onClick={() => this.createNewUser()}>Save</Button>
              <Button color="warning" onClick={() => this.setState({ isAddUser: false })}>Cancel</Button>
            </FormGroup>
          </div>
        }
        <Row>
          {users.map(user => (
            <Col xs={12} sm={4} md={3}>
              <div className="user_detail">
                <h5>{user.client_id}</h5>
                <p>{user.first_name} {user.middle_name} {user.last_name}</p>
                <Button color="primary" size="sm" onClick={() => this.setState({ newUser: user, isAddUser: true })}>Edit</Button>
                <Button color="danger" size="sm" outline  onClick={() => this.deactivateUser(user)}>Deactivate</Button>
              </div>
            </Col>
          ))}
        </Row>
      </div>
    );
  }
}