import React, { Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { Container } from 'reactstrap';
import Main from './pages/Main';
import NavigationBar from './components/NavigationBar';
import './App.css';
import 'react-toastify/dist/ReactToastify.css';

class App extends Component {
  render() {
    return (
      <div>
        <NavigationBar />
        <Container fluid>
          <Main />
        </Container>
        <ToastContainer position={toast.POSITION.TOP_RIGHT} />
      </div>
    );
  }
}

export default App;
