import React from 'react';
import { Link } from 'react-router-dom';
import './index.css';

export default class Loading extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentWillMount() {
    
  }
  render() {
    const { profile } = this.props;
    return (
      <div className="profile-main">
        <Link to={`/profile/${profile.brand}/${profile.barcode}`} target="_blank">
          <h6>{profile.name} - {profile.barcode}</h6>
        </Link>
        <p className="profile-main--barcode">{profile.email}</p>
        <p className="profile-main--brand">{profile.mobile}</p>
        <p className="profile-main--barcode">App user id: {profile.app_user_id}</p>
        <p className="profile-main--barcode">Gender: {profile.gender}</p>
      </div>
    );
  }
}