import Cookies from 'js-cookie';
import Constant from '../constants';

async function futch(url, opts={}, onProgress) {
    console.log(url, opts)
    return new Promise( (res, rej)=>{
        var xhr = new XMLHttpRequest();
        xhr.open(opts.method || 'get', url);
        for (var k in opts.headers||{})
            xhr.setRequestHeader(k, opts.headers[k]);
        xhr.onload = e => res(e.target);
        xhr.onerror = rej;
        if (xhr.upload && onProgress)
            xhr.upload.onprogress = onProgress; // event.loaded / event.total * 100 ; //event.lengthComputable
        xhr.send(opts.body);
    });
}

async function fetchAPI({ method, uri, body, success, error, serviceBaseUrl }) {
    let headers = {
        'Accept': '*/*',
        'Access-Control-Allow-Origin':'*',
    }
    if (body instanceof FormData || body instanceof URLSearchParams) {
        headers = {};
    }
    else {
        headers['Content-Type'] = 'application/json';
    }

    if (!method) {
        method = 'get'
    } 
    // Build complete API url
    // If service URL is specified, use it, otherwise use default API url
    let apiUrl = "";
    if (serviceBaseUrl) {
        apiUrl = serviceBaseUrl + uri;
    }
    else {
        apiUrl = Constant.apiBaseUrl + uri;
    }
    
    console.log('Calling api ' + apiUrl);

    // Get access token and attach it to API request's header
    const access_token = await Cookies.get('access_token');
    if (access_token != null) {
        headers['Authorization'] = `Bearer ${access_token}`;
    }
    else {
        console.log('No token is stored');
    }

    // Construct fetch options
    let fetchOptions = { method, headers };

    console.log(fetchOptions);

    // Only add fetch body when method is PUT or POST
    if (method.toLowerCase() === 'post' || method.toLowerCase() === 'put' || method.toLowerCase() === 'delete') {
        if (body instanceof FormData || body instanceof URLSearchParams) {
            fetchOptions.body = body;
        }
        else {
            if (body) {
                fetchOptions.body = JSON.stringify(body);
            }
        }
    }
    // Run the fetching
    return fetch(apiUrl, fetchOptions)
    .then((responseJson) => responseJson.json())
    .then((response) => success(response))
    .catch((errorMessage) => {
        if (errorMessage) {
            if (error) {
                error(errorMessage);
                alert('Đã xảy ra lỗi khi xử lý API');
            }
        }
        else {
            console.log(errorMessage);
            alert('Đã xảy ra lỗi khi xử lý API');
        }
    });
}

function getUser() {
    const user = localStorage.getItem('access_token');
    return user;
}

export default {
    fetchAPI: fetchAPI,
    futch: futch,
    getUser: getUser,
}