import React from 'react';
import './index.css';
import Loading from '../../components/Loading';
import Profile from '../../components/Profile';
import { Button, FormGroup, Label, Input, Row, Col } from 'reactstrap';
import api from '../../api';

const default_group = {
  name: '',
  is_deleted: 0,
}

export default class UserGroupsScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      permissions: null,
      isLoading: false,
      isAddGroup: false,
      newGroup: default_group,
      userGroups: null,
    }
  }
  componentDidMount = () => {
    document.title = "User groups | TCH Maria";
    this.getPermissions();
    this.getUserGroups();
  }
  tooglePermissions(permission) {
    const { newGroup } = this.state;
    if (!newGroup.permissions) {
      newGroup.permissions = [];
    }
    
    const existing_permission_ids = newGroup.permissions.map(permission => permission.id);

    if (existing_permission_ids.includes(permission.id)) {
      const index = existing_permission_ids.indexOf(permission.id);
      if (index > -1) {
        newGroup.permissions.splice(index, 1);
      }
    } else {
      newGroup.permissions.push(permission);
    }
    
    this.setState({ newGroup });
  }
  createNewGroup() {
    const { newGroup } = this.state;
    api.fetchAPI({
      method: newGroup.id ? 'put' : 'post',
      uri: `user_groups`,
      body: newGroup,
      success: (response) => {
        if (response.status === 'ok') {
          this.setState({ 
            newGroup: default_group,
          });
          this.getUserGroups();
        } else {
          alert(response.message);
        }
        this.setState({ isLoading: false, isAddGroup: false });
      },
    });
  }
  getPermissions() {
    this.setState({ isLoading: true, permissions: [] });
    api.fetchAPI({
      method: 'get',
      uri: `permissions`,
      success: (response) => {
        if (response.status === 'ok') {
          this.setState({ permissions: response.data.permissions, isLoading: false });
        }
      },
    });
  }
  getUserGroups() {
    this.setState({ isLoading: true, users: [] });
    api.fetchAPI({
      method: 'get',
      uri: `user_groups`,
      success: (response) => {
        if (response.status === 'ok') {
          this.setState({ userGroups: response.data.user_groups, isLoading: false });
        }
      },
    });
  }
  deactivateGroup(group) {
    api.fetchAPI({
      method: 'delete',
      uri: `user_groups`,
      body: group,
      success: (response) => {
        if (response.status === 'ok') {
          this.getUserGroups()
        }
        this.setState({ isLoading: false, isAddGroup: false, });
      },
    });
  }
  editUserValue = ({ property, value }) => {
    const { newGroup } = this.state;
    newGroup[property] = value ? value : '';
    this.setState({ newGroup });
  }
  render() {
    const { currentUser } = this.props;
    if (!currentUser) return null;
    const currentUserPermissions = currentUser.permission_names;
    const isCurrentUserAdmin = currentUser.is_system_admin;

    if (!currentUserPermissions.indexOf('can_create_user') && !isCurrentUserAdmin) return null;
    
    const { isLoading, permissions, isAddGroup, newGroup, userGroups } = this.state;
    if (!userGroups) return null;

    let permissions_ids = [];
    if (newGroup.permissions) {
      permissions_ids = newGroup.permissions.map(permission => permission.id);
    }
    
    return (
      <div className="users__main">
        {!isAddGroup &&
          <Button color="primary" onClick={() => this.setState({ isAddGroup: true })}>Add new group</Button>
        }
        {isAddGroup &&
          <div className="users__add-new">
            <FormGroup>
              <Input
                type="text"
                placeholder="Tên group"
                value={newGroup.name}
                onChange={(e) => this.editUserValue({ property: 'name', value: e.target.value })}
              />
            </FormGroup>
            {permissions &&
              <div className="user_group_selection">
                <h4>Permissions</h4>
                {permissions.map(permission => 
                  <Button
                    className="user_group_item"
                    onClick={() => this.tooglePermissions(permission)}
                    color={permissions_ids.includes(permission.id) ? 'info' : 'secondary'}
                    key={permission.id}
                  >
                    {permission.name}
                  </Button>
                )}
              </div>
            }
            <FormGroup>
              <Button color="success" onClick={() => this.createNewGroup()}>Save</Button>
              <Button color="warning" onClick={() => this.setState({ isAddGroup: false })}>Cancel</Button>
            </FormGroup>
          </div>
        }
        <Row>
          {userGroups.map(group => (
            <Col xs={12} sm={4} md={3} key={group.id}>
              <div className="user_detail">
                <h5>{group.name}</h5>
                <Button color="primary" size="sm" onClick={() => this.setState({ newGroup: group, isAddGroup: true })}>Edit</Button>
                <Button color="danger" size="sm" outline  onClick={() => this.deactivateGroup(group)}>Deactivate</Button>
              </div>
            </Col>
          ))}
        </Row>
      </div>
    );
  }
}