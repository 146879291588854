import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import './index.css';

// The Roster component matches one of two different routes
// depending on the full pathname
export default class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
      }
    componentDidMount = () => {
        document.title = "TCH Maria";
    }
    render() {
        const { currentUser } = this.props;
        if (!currentUser) return null;
        const currentUserPermissions = currentUser.permission_names;
        const isCurrentUserAdmin = currentUser.is_system_admin;
        return (
            <Row className="home__content">
                <Col xs="6" sm="4" lg="2" className="home__navigation-item">
                    <Link to='/segmentation' className="home__navigation-item-container">
                        <img className="img-fluid home__navigation-item-img" src="/assets/segmentation.png" alt="Segment manager" />
                        <h4>Segment manager</h4>
                    </Link>
                </Col>
                <Col xs="6" sm="4" lg="2" className="home__navigation-item">
                    <Link to='/schedules' className="home__navigation-item-container">
                        <img className="img-fluid home__navigation-item-img" src="/assets/schedule.png" alt="Schedule actions" />
                        <h4>Scheduled actions</h4>
                    </Link>
                </Col>
                <Col xs="6" sm="4" lg="2" className="home__navigation-item">
                    <Link to='/newsfeeds' className="home__navigation-item-container">
                        <img className="img-fluid home__navigation-item-img" src="/assets/newsfeed.png" alt="News Feed" />
                        <h4>Newsfeed</h4>
                    </Link>
                </Col>
                <Col xs="6" sm="4" lg="2" className="home__navigation-item">
                    <Link to='/analytics' className="home__navigation-item-container">
                        <img className="img-fluid home__navigation-item-img" src="/assets/analytics.png" alt="Analytics" />
                        <h4>Analytics</h4>
                    </Link>
                </Col>
                <Col xs="6" sm="4" lg="2" className="home__navigation-item">
                    <Link to='/pos/settings/photos' className="home__navigation-item-container">
                        <img className="img-fluid home__navigation-item-img" src="/assets/photos.png" alt="POS Photos" />
                        <h4>POS photos</h4>
                    </Link>
                </Col>
                {(currentUserPermissions.indexOf('can_create_user') || isCurrentUserAdmin) &&
                    <Col xs="6" sm="4" lg="2" className="home__navigation-item">
                        <Link to='/users' className="home__navigation-item-container">
                            <img className="img-fluid home__navigation-item-img" src="/assets/photos.png" alt="POS Photos" />
                            <h4>Users</h4>
                        </Link>
                    </Col>
                }
                {(currentUserPermissions.indexOf('can_create_user_group') || isCurrentUserAdmin) &&
                    <Col xs="6" sm="4" lg="2" className="home__navigation-item">
                        <Link to='/user_groups' className="home__navigation-item-container">
                            <img className="img-fluid home__navigation-item-img" src="/assets/photos.png" alt="POS Photos" />
                            <h4>User Groups</h4>
                        </Link>
                    </Col>
                }
            </Row>
        )
    };
}
