import React from 'react';
import { Button, Alert } from 'reactstrap';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { Link } from 'react-router-dom';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { TagCloud } from "react-tagcloud";
import _ from 'lodash';

import Action from '../../components/Action';
import Loading from '../../components/Loading';
import api from '../../api';
import normalizeAction from '../../resources/normalizers/action';

import './index.css';
export default class SegmentDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      actions: [],
      segment_id: this.props.match.params.segment_id,
      isLoading: false,
      message: '',
      segment: {},
      totalUser: 'Getting user count...',
      keywords: [],
      isFacebookAudienceSyncing: false,
      campaignDiscountRate: 10,
      campaignPerformance: null,
      isCalculatingPrediectedPerformance: false,
    }
    this.predictCampaignPerformance = _.debounce(this.predictCampaignPerformance, 1000)
  }
  componentDidMount = async () => {
    document.title = "Segment Actions | TCH Maria"
    await this.getSegmentActions();
    this.getSegmentUserCount();
    // this.getSegmentKeywords();
  }
  getSegmentActions = async () => {
    this.setState({ isLoading: true });
    api.fetchAPI({
        method: 'get',
        uri: `segment/${this.state.segment_id}/actions`,
        success: (response) => {
          this.setState({
            actions: response.data.actions.map(normalizeAction),
            segment: response.data,
            isLoading: false,
          });
        },
    });
  }
  getSegmentUserCount = () => {
    api.fetchAPI({
        method: 'get',
        uri: `segment/${this.state.segment_id}/count`,
        success: (response) => {
          if (response.status === 'ok') {
            this.setState({
              totalUser: response.data.total,
            });
          } else {
            this.setState({
              totalUser: 0,
              message: response.data.message,
            });
          }
        },
    });
  }
  getSegmentKeywords() {
    api.fetchAPI({
        method: 'get',
        uri: `segment/${this.state.segment_id}/keywords`,
        success: (response) => {
          if (response.status === 'ok') {
            this.setState({
              keywords: response.data.keywords,
            });
          }
        },
    });
  }
  createFacebookAudience() {
    const { segment_id } = this.state;
    this.setState({ isFacebookAudienceSyncing: true });
    api.fetchAPI({
      method: 'post',
      uri: `facebook/audience?segment_id=${segment_id}`,
      success: (response) => {
        if (response.status === 'ok') {
          const number_of_user_synced = response.data.add_user_to_facebook_audience_info.num_received;
          const num_invalid_entries = response.data.add_user_to_facebook_audience_info.num_invalid_entries;
          alert(`Matched ${number_of_user_synced} users, ${num_invalid_entries} invalid`);
        } else {
          alert('Error while syncing segment');
        }
        this.setState({ isFacebookAudienceSyncing: false });
      },
  });
  }
  createAction = () => {
    const action = {
      action: 'create',
      segment_id: this.state.segment_id,
      type: 'notification',
      segment: {
        brand: this.state.segment && this.state.segment.brand ? this.state.segment.brand : 'tch',
      },
    }
    this.setState(previousState => ({
        actions: [action, ...previousState.actions]
    }));
  }
  setCampaignDiscountRate(value) {
    this.setState({ campaignDiscountRate: value }, this.predictCampaignPerformance)
  }
  predictCampaignPerformance() {
    const { campaignDiscountRate } = this.state;
    this.setState({ isCalculatingPrediectedPerformance: true, campaignPerformance: null });
    api.fetchAPI({
      method: 'get',
      uri: `segment/${this.state.segment_id}/campaign_predictions?discount_rate=${campaignDiscountRate}`,
      success: (response) => {
        if (response.status === 'ok') {
          this.setState({
            campaignPerformance: response.data.prediction_result,
            isCalculatingPrediectedPerformance: false,
          });
        } else {
          this.setState({ isCalculatingPrediectedPerformance: false });
        }
      },
  });
  }
  render() {
    const { message, segment, totalUser, actions, isLoading,
      keywords,
      isFacebookAudienceSyncing,
      isCalculateCampaignScheme,
      campaignDiscountRate,
      campaignPerformance,
      isCalculatingPrediectedPerformance,
    } = this.state;

    return (
      <div className="action-main">
        <h5 className="action-title">
          {segment.brand && segment.brand !== '' ?
            <span className={`segment--brand ${segment.brand === 'tch' ? 'segment--brand---tch' : 'segment--brand---tenren'}`}>{segment.brand}</span>
            :
            null
          }
          {segment.bigquery_query ? <span className={`segment--brand segment--brand---bigquery`}>BigQuery</span> : null} 
          {segment.name}
        </h5>
        {segment && segment.name &&
          <div className="segment-edit-button">
            <Button tag={Link} to={`/segment/${segment.segment_id}/edit`} color="info">Edit segment</Button>
          </div>
        }
        {segment.query && segment.query !== '' ?
          <Alert color="info" className="query-text">
            Query:
            <SyntaxHighlighter language="sql" style={docco}>
              {segment.query}
            </SyntaxHighlighter>
          </Alert>
          :
          null
        }
        {segment.bigquery_query && segment.bigquery_query !== '' ?
          <Alert color="primary" className="query-text">
            BigQuery: {segment.bigquery_query_negate_operator}
            <SyntaxHighlighter language="sql" style={docco}>
              {segment.bigquery_query}
            </SyntaxHighlighter>
          </Alert>
          :
          null
        }
        <h6 className="text-success">Total user: {totalUser} users</h6>
        {message ?
          <p className="text-danger">{message}</p>
          :
          null
        }
        <div className="action-created-container">
          <Button color="success" onClick={()=>this.createAction()}>Create new action</Button>
        </div>
        <div className="action-created-container">
          <Button
            color={`${isFacebookAudienceSyncing ? 'secondary' : 'primary'}`}
            disabled={isFacebookAudienceSyncing} onClick={()=>this.createFacebookAudience()}
          >
            {isFacebookAudienceSyncing ? 'Đang sync, chờ xíu...' : 'Sync Facebook Audience (beta)'}
          </Button>
        </div>
        <div className="campaign-prediction-outter">
          {!isCalculateCampaignScheme ?
            <div>
              <Button
                color="primary"
                onClick={() => this.setState({ isCalculateCampaignScheme: true })}
              >
                Bấm vào để xem dự báo hiệu quả campaign
              </Button>
            </div>
            :
            <div className="campaign-prediction">
              <h5>Tỉ lệ giảm giá: {campaignDiscountRate}%</h5>
              <input
                className="redeemption-rate-slider"
                type="range"
                min="1" max="100"
                value={campaignDiscountRate}
                onChange={(e) => this.setCampaignDiscountRate(e.target.value)}
              />
              {isCalculatingPrediectedPerformance &&
                <p>Đang tính toán, chờ xíu nhen...</p>
              }
              {campaignPerformance &&
                <div>
                  <p>Số người sẽ nhận coupon: {campaignPerformance.target}</p>
                  <p>Số người có thể dự đoán: {campaignPerformance.predictable}</p>
                  <p>Số người sẽ mua hàng: {campaignPerformance.redeem}</p>
                  <p>Tỉ lệ sẽ mua: {Math.round((campaignPerformance.redeem / campaignPerformance.predictable * 100), 2)}%</p>
                  <p>Thời gian dự đoán: {Math.round((campaignPerformance.elapse_seconds), 2)} giây</p>
                </div>
              }
            </div>
          }
        </div>
        {keywords && keywords.length > 0 &&
          <div>
            {!isCalculateCampaignScheme &&
              <div>
                <h6>Keyword nên dùng khi soạn nội dung</h6>
                <Alert color="warning" className="segment-keywords-container">
                  <TagCloud
                    minSize={12}
                    maxSize={40}
                    tags={keywords}
                    colorOptions={{
                      luminosity: 'bright',
                      hue: 'blue'
                    }}
                  />
                </Alert>
              </div>
            }
          </div>
        }
        {isLoading ?
          <Loading />
          :
          <div>
            <h6>Các actions</h6>
            {actions.map((action) => <Action key={action.action_id} action={action} display_mode="action" confirmMoveToAnotherSegment={() => this.confirmMoveToAnotherSegment()} />)}
          </div>
        }
      </div>
    );
  }
}