import React from 'react';
import './index.css';
import Loading from '../../components/Loading';
import Profile from '../../components/Profile';
import { Button, FormGroup, Label, Input, Row, Col } from 'reactstrap';
import api from '../../api';

export default class Profiles extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      brand: this.props.match.params.brand,
      barcode: this.props.match.params.barcode,
      isLoading: false,
      totalResult: null,
    }
    this.startSearch = this.startSearch.bind(this);
  }
  componentDidMount = () => {
    document.title = "Profiles | TCH Maria";
  }
  searchProfiles = (queryString) => {
    this.setState({ queryString });
  }
  searchKeyPress = (target) => {
    if(target.charCode === 13){
      this.startSearch();    
    }
  }
  startSearch = () => {
    this.setState({ isLoading: true, profiles: [], totalResult: null });
    api.fetchAPI({
      method: 'get',
      uri: `profiles?q=${this.state.queryString}&brand=tch`,
      success: (response) => {
        if (response.status === 'ok') {
          this.setState({ profiles: response.data, isLoading: false, totalResult: response.total });
        }
        this.setState({ isLoading: false });
      },
    });
  }
  render() {
    const { isLoading, profiles, totalResult } = this.state;
    return (
      <div className="main">
        <Row>
          <Col xs="12">
            <FormGroup>
              <Label for="q">Barcode, name, etc...</Label>
              <Input
                type="text"
                name="q"
                placeholder="Type in barcode"
                onChange={(e) => this.searchProfiles(e.target.value)}
                onKeyPress={(target) => this.searchKeyPress(target)}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xs="12">
            <FormGroup>
              <Button
                disabled={isLoading}
                color={isLoading === false ? 'primary' : 'secondary'}
                onClick={() => this.startSearch()}
              >
                {isLoading ? 'Searching for matched users...' : 'Search'}
              </Button>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          {isLoading ? <Loading /> : null}
          <Col xs="12">
            {totalResult ? <h5>Found {totalResult} profile(s)</h5> : null}
          </Col>
          {profiles ?
            profiles.map(profile => (
              <Col xs="12" sm="4" md="3">
                <Profile profile={profile} />
              </Col>
            ))
            :
            null
          }
        </Row>
      </div>
    );
  }
}