import React from 'react';
import { FormGroup, Input, Label, Button, Row, Col, Alert, Badge } from 'reactstrap';
import './index.css';
import DeepLink from './DeepLink';
import ButtonAction from './Buttons/ButtonAction';
import api from '../../../api';
export default class Notification extends React.Component {
    constructor(props) {
        super(props);

        let actionConfigInit = {}
        if (!this.props.action.action_config) {
            actionConfigInit = {
                icon: "default",
                type: "popup",
                image: "",
                title: "",
                content: "",
                deeplink: "",
                push_type: "coupon",
                buttons: [
                    {
                        title: "Xem ngay"
                    },
                ],
                brand: 'tch',
                user_login_status: 'login_only',
                fileList: [],
            };
        }
        else {
            actionConfigInit = this.props.action.action_config;
        }

        this.state = {
            action: this.props.action,
            editMode: this.props.editMode,
            actionConfig: actionConfigInit,
            showDeepLinkBuilder: false,
            createNewButtonText: '',
        };
    }
    componentWillMount() {
        // this.setState({ action: this.props.action });
    }
    editActionValue = ({ property, value }) => {
        const actionConfig = this.state.actionConfig;
        actionConfig[property] = value;
        this.setState({ actionConfig });
        this.props.updateConfig(actionConfig);
    }
    toogleDeepLinkBuilder = () => {
        const showDeepLinkBuilder = this.state.showDeepLinkBuilder;
        this.setState({ showDeepLinkBuilder: showDeepLinkBuilder === true ? false : true });
    }
    onButtonCreated = (button) => {
        const { actionConfig } = this.state;
        const buttons = actionConfig.buttons ? actionConfig.buttons : [];
        buttons.push(button);
        actionConfig.buttons = buttons;
        this.setState({ actionConfig });
    }
    removeNotificationButtons = (button) => {
        const actionConfig = this.state.actionConfig
        const buttons = actionConfig.buttons ? actionConfig.buttons : [];
        buttons.splice(buttons.findIndex((el)=> {
            return el.title === button.title && el.action === button.action;
        }), 1);

        actionConfig.buttons = buttons;
        this.setState({ actionConfig });
    }
    onSelectFileHandler(event) {
        const fileList = Array.from(event.target.files) || [];
        this.setState({ fileList });
    }
    async uploadImages() {
        const { fileList } = this.state;
        if (fileList.length > 0) {
          await fileList.map(file => this.uploadImage(file));
        }
    }
    uploadImage(file) {
        const { fileList } = this.state;
        const submitData = new FormData();
        submitData.set('user_file', file);
        submitData.set('brand', 'tch');
        submitData.set('screen_name', 'notification');
        this.setState({ isUploading: true });
        api.fetchAPI({
          method: 'post',
          body: submitData,
          uri: `pos/settings/second_screen_photos`,
          success: (response) => {
            console.log(response);
            if (response.status === 'ok') {
                const { actionConfig } = this.state;

                // Set image to notification
                const imageUrl = response.data.image.image_url;
                actionConfig.image = imageUrl;

                // Remove file from UI
                const removeIndex = fileList.indexOf(file);
                if (removeIndex > -1) {
                    fileList.splice(removeIndex, 1);
                }

                this.setState({ isUploading: false, fileList, actionConfig });
            } else {
                alert(response.data.message);
                this.setState({ isUploading: false });
            }
          },
        });
    }
    render() {
        const { fileList, actionConfig, editMode, isUploading } = this.state
        return (
        <div className="segment">
            {editMode === true?
                <div>
                    <p>Notification</p>
                    <FormGroup>
                        <Label>Title ({actionConfig.title.length} kí tự)</Label>
                        <Input type="text" name="text" placeholder="Title" value={this.state.actionConfig.title} onChange={(e) => this.editActionValue({ property: 'title', value: e.target.value })} maxLength={30} />
                    </FormGroup>
                    <FormGroup>
                        <Label>Content ({actionConfig.content.length} kí tự)</Label>
                        <Input type="textarea" name="text" placeholder="Content" value={this.state.actionConfig.content} onChange={(e) => this.editActionValue({ property: 'content', value: e.target.value })} />
                        <p>
                            You are allow to use custom attributes to personalize user message. Example: Chào bạn [[name]], lâu rồi chưa thấy bạn quay lại TCH đó nha
                            <br />
                            <span><b>Available attributes</b>: name, brand (Tenren or TCH), recency,
                                most_popular_product_name, most_deli_popular_product_name, most_popular_cake,
                                most_deli_popular_cake
                            </span>
                        </p>
                    </FormGroup>
                    <FormGroup>
                        <Label>User login status</Label>
                        <Input
                            type="select"
                            value={actionConfig.user_login_status}
                            onChange={(e) => this.editActionValue({ property: 'user_login_status', value: e.target.value })}
                        >
                            <option value="all">All</option>
                            <option value="login_only">Login only</option>
                            <option value="logout_only">Logout only</option>
                        </Input>
                    </FormGroup>
                    {/* <FormGroup>
                        <Label>Push Type</Label>
                        <Input type="text" name="text" placeholder="Push Type" value={this.state.actionConfig.push_type} onChange={(e) => this.editActionValue({ property: 'push_type', value: e.target.value })} />
                    </FormGroup> */}
                    <FormGroup>
                        <Label>Type</Label>
                        <Input
                            type="select"
                            value={actionConfig.type}
                            onChange={(e) => this.editActionValue({ property: 'type', value: e.target.value })}
                        >
                            <option value="popup">Pop Up</option>
                            <option value="deeplink">Deep Link</option>
                        </Input>
                    </FormGroup>
                    <FormGroup>
                        <Label>Push Type Icon</Label>
                        <Input
                            type="select"
                            value={actionConfig.push_type}
                            onChange={(e) => this.editActionValue({ property: 'push_type', value: e.target.value })}
                        >
                            <option value="default">Default</option>
                            <option value="coupon">Coupon</option>
                            <option value="voucher">Voucher</option>
                            <option value="stars">Stars</option>
                        </Input>
                    </FormGroup>
                    <FormGroup>
                        <Label>Image</Label>
                        <Input type="text" name="text" placeholder="Image" value={actionConfig.image} onChange={(e) => this.editActionValue({ property: 'image', value: e.target.value })} />
                        <br />
                        <FormGroup>
                            <Input type="file" onChange={(event) => this.onSelectFileHandler(event)}>
                                Select new photos
                            </Input>
                        </FormGroup>
                        <Row>
                            {fileList && fileList.length > 0 &&
                                fileList.map((file, index) =>
                                <Col xs="12" sm="4" md="3" lg="2" key={index}>
                                    <div className="pos-photos__img-container">
                                    <img className="pos-photos__img" src={URL.createObjectURL(file)} alt={index} />
                                    <div className="pos-photos__img-delete">
                                        <Button size="sm" color={isUploading ? 'secondary' : 'primary'} disabled={isUploading} onClick={() => this.uploadImages()}>
                                            {isUploading ? 'Uploading...' : 'Upload'}
                                        </Button>
                                    </div>
                                    </div>
                                </Col>
                                )
                            }
                        </Row>
                    </FormGroup>
                    <h5 className="deep-link-builder-button" onClick={() => this.toogleDeepLinkBuilder()}>Deep Link Builder</h5>
                    {this.state.showDeepLinkBuilder ?
                        <DeepLink editMode={true} coupon_code={this.state.action.coupon_code} brand={this.state.action.segment ? this.state.action.segment.brand : 'tch'} />
                        :
                        <div />
                    }
                    <FormGroup>
                        <Label>Deep Link</Label>
                        <Input type="text" name="text" placeholder="Deep Link" value={this.state.actionConfig.deeplink} onChange={(e) => this.editActionValue({ property: 'deeplink', value: e.target.value })} />
                    </FormGroup>
                    <FormGroup>
                        <ButtonAction couponCode={this.state.action.coupon_code} onButtonCreated={(button) => this.onButtonCreated(button)}/>
                    </FormGroup>
                    <FormGroup>
                        {this.state.actionConfig.buttons ?
                            <Row>
                                {this.state.actionConfig.buttons.map(button => 
                                    <Col xs="6" sm="3" key={button.title}>
                                        <Alert color="primary">
                                            <p><Badge color="primary">{button.action ? button.action[0].payload.screen : ''}</Badge> <b>{button.title}</b></p>
                                            <Button onClick={() => this.removeNotificationButtons(button)}>Xóa</Button>
                                        </Alert>
                                    </Col>
                                )}
                            </Row>
                            :
                            null
                        }
                    </FormGroup>
                </div>
                :
                <div/>
            }
        </div>
        );
    }
}