import React from 'react';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';

import './index.css';

import "react-datepicker/dist/react-datepicker.css";

export default class GhostCMS extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      
    }
  }
  componentDidMount = () => {
    document.title = "Newsfeed | TCH Maria";
  }
  render() {
    return (
      <div className="ghost-cms__container">
        <div className="ghost-cms__navigation">
          <Button tag={Link} color="warning" to='/newsfeed/posts'>Edit Post Extra data</Button>
          <Button tag={Link} color="danger" to='/newsfeed/tags'>Edit Tags</Button>
        </div>
        <iframe
          src="https://feed.thecoffeehouse.com/ghost/#/"
          frameBorder="0"
          className="ghost-cms__iframe"
          title="iframe-analytics"
        />
      </div>
    );
  }
}