import React from 'react';
import { Table, Button, Input } from 'reactstrap';

import './index.css';
import Loading from '../../../components/Loading';
import api from '../../../api';

export default class ListTags extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      isUpdate: false,
      tagId: null,
      data: {}
    }
    this.onEditTags = this.onEditTags.bind(this)
    this.onUpdateTags = this.onUpdateTags.bind(this)
    this.onChangeData = this.onChangeData.bind(this)
    this.stringToSlug = this.stringToSlug.bind(this)
  }
  componentDidMount = () => {
    document.title = "Newsfeed Posts | TCH Maria";
    this.getTags();
  }
  getTags = () => {
    api.fetchAPI({
      method: 'get',
      uri: `newsfeed/tags`,
      success: (response) => {
        console.log(response)
        this.setState({
          tags: response.data,
          isLoading: false,
        });
      }
    });
  }

  stringToSlug = (str) => {
    str = str.replace(/^\s+|\s+$/g, ''); // trim
    str = str.toLowerCase();

    // remove accents, swap ñ for n, etc
    var from = "àáảạãäâậẫấầẵặắằăèéẻẽẹëêểễếềịĩìíïîỉòóõọöôổồốỗộơờớỡợởưừứựưửụủùúüûñç·/_,:;";
    var to   = "aaaaaaaaaaaaaeeeeeeeeeeeeeeiiiiiiiooooooooooooooooouuuuuuuuuuuunc------";
    for (var i=0, l=from.length ; i<l ; i++) {
        str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }

    str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
        .replace(/\s+/g, '-') // collapse whitespace and replace by -
        .replace(/-+/g, '-'); // collapse dashes

    return str;
  }

  onEditTags(e, tagId) {
    let parentEle = document.getElementById(tagId)
    let tdSelector = parentEle.querySelectorAll('td')

    let data = {}
    tdSelector.forEach(function (a, i) {
      if (i >= 0 && i < (tdSelector.length - 1)) {
        let text = parentEle.getElementsByTagName('td')[i].textContent
        if (i === 0) {
          data.name = text
        }
        if (i === 1) {
          data.slug = text
        }
        if (i === 2) {
            data.description = text
        }
        if (i === 3) {
          data.priority = text
        }
      }
    })
    this.setState({
      isUpdate: !this.state.isUpdate,
      tagId: tagId,
      data: data
    })
  }

  onUpdateTags(tagId) {
    api.fetchAPI({
      method: 'PUT',
      uri: 'newsfeed/tags/' + tagId,
      body: this.state.data,
      success: function (res) {
        console.log(res)
        if (res.status === 'ok') {
          alert('Cập nhật thành công')
          window.location.reload()
        }
      },
      error: function (err) {
        console.log(err)
      }
    })
  }

  onChangeData(e) {
    let keyObj = e.target.name
    let value = e.target.value
    this.setState(prevState => {
      let data = {...prevState.data}
      data[keyObj] = value
      if (keyObj === 'name') {
        data['slug'] = this.stringToSlug(value)
      }
      return {data}
    })
  }

  render() {
    const { tags } = this.state;
    return (
      <div className="main">
        {this.state.isLoading ?
          <Loading />
          :
          <div className='ghost-cms'>
            <Button className="ghost-cms_nav_goBack" color="warning" onClick={() => {this.props.history.goBack()}}>Go back</Button>
            <Table striped bordered hover responsive>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Slug</th>
                  <th>Description</th>
                  <th>Priority</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                  {tags.map((tag, index) => {
                    if (this.state.tag !== null && !this.state.isUpdate) {
                      return (
                        <tr key={index} id={tag.tag_id}>
                          <td>{tag.name}</td>
                          <td>{tag.slug}</td>
                          <td>{tag.description}</td>
                          <td>{tag.priority}</td>
                          <td className='action'>
                            <Button color='primary' onClick={((e) => this.onEditTags(e, tag.tag_id))}>
                              Edit
                            </Button>
                          </td>
                        </tr>
                      )
                    } else {
                      if (this.state.tagId === tag.tag_id && this.state.isUpdate) { // Update post info
                        return (
                          <tr key={index} id={tag.tag_id}>
                            <td><Input name='name' value={this.state.data.name} onChange={this.onChangeData}/></td>
                            <td><Input name= 'slug' value={this.state.data.slug ? this.state.data.slug : ""} onChange={this.onChangeData} disabled/></td>
                            <td><Input name='description' value={this.state.data.description} onChange={this.onChangeData} /></td>
                            <td><Input name='priority' type="number" value={this.state.data.priority} onChange={this.onChangeData} /></td>
                            <td className='action'>
                              <Button color='success' onClick={((e) => this.onUpdateTags(tag.tag_id))}>Save</Button></td>
                          </tr>
                        )
                      } else {
                        return (
                          <tr key={index} id={tag.tag_id}>
                              <td>{tag.name}</td>
                              <td>{tag.slug}</td>
                              <td>{tag.description}</td>
                              <td>{tag.priority}</td>
                              <td className='action'>
                                <Button color='primary' onClick={((e) => this.onEditTags(e, tag.tag_id))}>Edit</Button>
                              </td>
                          </tr>
                        )
                      }
                    }
                  })}
              </tbody>
            </Table>
          </div>
        }
      </div>
    );
  }
}