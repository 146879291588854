import normalizeAction from './action';

const normalizeSegment = segment => ({
    segment_id: segment.segment_id,
    name: segment.name,
    description: segment.description,
    query: segment.query,
    brand: segment.brand ? segment.brand : 'Unknown brand',
    created_at: segment.created_at,
    bigquery_query: segment.bigquery_query,
    bigquery_negate_operator: segment.bigquery_negate_operator,
    recommendation_text: segment.recommendation_text ? segment.recommendation_text : null,
    order_number: segment.order_number ? segment.order_number : 0,
    graph: segment.graph ? segment.graph : 0,
    actions: segment.actions ? segment.actions.map(normalizeAction) : [],
    tags: segment.tags,
    slug_name: segment.slug_name || '',
});

export default normalizeSegment;