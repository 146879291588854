import React from 'react';
import './index.css';

export default class Pagination extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  onPageClick(page) {
    const { onPageClick } = this.props;
    if (onPageClick) {
      onPageClick(page);
    }
  }
  render() {
    const { totalPages, activePage } = this.props;
    const pageNumberArray = [];
    for(let i=1; i <= totalPages; i++) {
      pageNumberArray.push(i);
    }
    return (
      <div className="pagination-main">
        {pageNumberArray.map(page => 
          <button
            key={page}
            className={`pagination-button ${activePage === page ? 'active' : ''}`}
            onClick={() => this.onPageClick(page)}
          >
            {page}
          </button>
        )}
      </div>
    );
  }
}