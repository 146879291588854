import React from 'react';
import './index.css';
import Segment from '../../components/Segment';
import normalizeSegment from '../../resources/normalizers/segment';
import api from '../../api';

export default class Schedules extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        actions: [],
        segment_id: this.props.match.params.segment_id,
    }
  }
  componentDidMount = () => {
    document.title = "Schedule Actions | TCH Maria";
    const { segment_id } = this.state;
    api.fetchAPI({
        method: 'get',
        uri: `segment/${segment_id}`,
        success: (response) => {
          const segment = normalizeSegment(response.data);
          segment.action = 'update'
          this.setState({ segment });
        }
    })
  }
  render() {
    const { segment } = this.state;
    return (
      <div className="main">
        <h4>Edit segment</h4>
        {segment &&
          <Segment segment={segment} editMode />
        }
      </div>
    );
  }
}