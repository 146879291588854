import React from 'react';
import { Row, Col, Progress } from 'reactstrap';
import './index.css';

export default class Segment extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            history: {},
            isSending: false,
        };
    }
    componentWillMount() {
        this.setState({ history: this.props.history });
    }
    render() {
        const sending_percentage = this.state.history.noti_sent / this.state.history.total_users * 100;
        return (
            <div className="action-history">
                <Row>
                    <Col xs="6" sm="3" md="3">
                        <p>
                            <span className="action-history--job">Job {this.state.history.log_id}</span>
                            <span className={`action-history--status action-history--status--${this.state.history.status}`}>{this.state.history.status}</span>
                        </p>
                        <p className="action-history--datetime">{this.state.history.created_at}</p>
                    </Col>
                    <Col xs="6" sm="3" md="3">
                        <p>{this.state.history.parent_job_id}</p>
                    </Col>
                    <Col xs="12" sm="3" md="3">
                        <p>{this.state.history.note}</p>
                    </Col>
                    <Col xs="12" sm="3" md="3">
                        <p>Sent: {this.state.history.noti_sent}</p>
                        <p>Total: {this.state.history.total_users}</p>
                    </Col>
                </Row>
                {this.state.history.status === 'running' ?
                    <Progress animated color="success" value={sending_percentage}>
                        {parseFloat(sending_percentage).toFixed(2)}%
                    </Progress>
                    :
                    <span/>
                }
            </div>
        );
    }
}