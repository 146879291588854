import React from 'react';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink} from 'reactstrap';
  import { Link } from 'react-router-dom';
import Cookies from 'js-cookie';
import './index.css';

export default class Example extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false,
      hasUser: false,
    };
  }
  async componentDidMount() {
    const access_token = await Cookies.get('access_token');
    if (access_token) {
      this.setState({ hasUser: true });
    }
  }
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }
  logout = async () => {
    //eslint-disable-next-line
    const r = confirm('Are you sure to log out?');
    if (r) {
      await Cookies.remove('access_token');
      await Cookies.remove('email');
      await Cookies.remove('user_id');
      this.setState({ hasUser: false });
      window.location.href = process.env.NODE_ENV === 'production' ? 'https://accounts.haravan.com/account/logout' : 'https://accounts.hara.vn/account/logout';
    }
  }
  render() {
    return (
      <div>
        <Navbar className="app-navigation-header" color="dark" dark expand="md">
          <NavbarBrand tag={Link} to={'/'}>
            TCH Maria
          </NavbarBrand>
          <NavbarToggler onClick={this.toggle} />
          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav className="ml-auto" navbar>
              <NavItem>
                <NavLink onClick={() => this.logout()}>{this.state.hasUser ? 'Logout' : ''}</NavLink>
              </NavItem>
            </Nav>
          </Collapse>
        </Navbar>
      </div>
    );
  }
}