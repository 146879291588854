import normalizeSegment from './segment';

const normalizeAction = action => ({
    action_id: action.action_id,
    segment_id: action.segment_id,
    name: action.name,
    description: action.description,
    type: action.type,
    cron_string: action.cron_string ? action.cron_string : '',
    schedule_job_id: action.schedule_job_id,
    segment: action.segment ? normalizeSegment(action.segment) : {},
    action_config: action.action_config ? action.action_config : {},
    status: action.status ? action.status : 'draft',
    permissions: action.permissions ? action.permissions : {},
});

export default normalizeAction;