import React from 'react';
import { Button, Input, Row, Col, FormGroup, Label } from 'reactstrap';
import _ from 'lodash';
import './index.css';
import Segment from '../../components/Segment';
import Loading from '../../components/Loading';
import Pagination from '../../components/Pagination';
import normalizeSegment from '../../resources/normalizers/segment';
import api from '../../api';


export default class Segmentation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      segments: [],
      totalNumberOfSegments: 0,
      page: 1,
      limit: 9,
      totalPages: 0,
      searchParams: null,
      order_by: 'created_at',
      order_type: 'desc',
      searchKeyword: '',
    };
    this.searchSegment = _.debounce(this.searchSegment, 300);
  }
  componentDidMount = () => {
    document.title = "Segmentation | TCH Maria";
    this.setState({ isLoading: true });
    this.loadSegments();
  }
  onPageClick(page) {
    this.setState({ page }, this.loadSegments);
  }
  loadSegments() {
    const { page, limit, searchKeyword, brand, order_by, order_type } = this.state;
    let uri = `segments?page=${page}&limit=${limit}&order_by=${order_by}&order_type=${order_type}`;

    if (searchKeyword) {
      uri = `${uri}&name=${searchKeyword}`;
    }

    if (brand) {
      uri = `${uri}&brand=${brand}`;
    }

    this.setState({
      segments: [],
      isLoading: true,
    });

    api.fetchAPI({
      method: 'get',
      uri,
      success: (response) => {
        if (response.status === 'ok') {
          this.setState({
            segments: response.data.segments.map(normalizeSegment),
            isLoading: false,
            totalNumberOfSegments: response.data.total_segments,
            totalPages: response.data.number_of_pages,
          });
        }
        else {
          alert(response.data.message);
        }
      }
    });
  }
  createSegment = () => {
    const segment = {
        action: 'create',
    }
    this.setState(previousState => ({
        segments: [segment, ...previousState.segments]
    }));
  }
  searchSegment = () => {
    this.loadSegments();
  }
  render() {
    const { totalPages, page, searchKeyword, brand, order_by, order_type } = this.state;
    return (
      <div className="segments__container">
        <h4>Segment manager</h4>
        <Row className="segments-search__container">
          <Col xs="12">
            <FormGroup>
              <Label>Tìm theo tên</Label>
              <input
                type="text"
                placeholder="Search for segment..."
                className="search-segment-textbox"
                value={searchKeyword}
                onChange={(e) => this.setState({ searchKeyword: e.target.value }, this.searchSegment)}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xs="12" sm="4" md="3">
            <FormGroup>
              <Label>Brand</Label>
              <Input
                type="select"
                value={brand}
                onChange={(e) => this.setState({ brand: e.target.value }, this.loadSegments)}
              >
                <option value="all">TCH & Tenren</option>
                <option value="tch">TCH</option>
                <option value="tenren">Tenren</option>
              </Input>
            </FormGroup>
          </Col>
          <Col xs="12" sm="4" md="3">
            <FormGroup>
              <Label>Sắp xếp theo</Label>
              <Input
                  type="select"
                  value={order_by}
                  onChange={(e) => this.setState({ order_by: e.target.value }, this.loadSegments)}
              >
                <option value="created_at">Thời gian tạo</option>
                <option value="updated_at">Thời gian update</option>
                <option value="name">Tên segment</option>
              </Input>
            </FormGroup>
          </Col>
          <Col xs="12" sm="4" md="3">
            <FormGroup>
              <Label>Thứ tự</Label>
              <Input
                  type="select"
                  value={order_type}
                  onChange={(e) => this.setState({ order_type: e.target.value }, this.loadSegments)}
              >
                <option value="desc">Giảm dần</option>
                <option value="asc">Tăng dần</option>
              </Input>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xs="12">
            <FormGroup>
              <Button color="success" onClick={()=>this.createSegment()}>
                Tạo segment mới
              </Button>
            </FormGroup>
          </Col>
        </Row>
        <Row className="segments-pagination__container">
          <Col xs="12">
            <Pagination totalPages={totalPages} onPageClick={(pageNumber) => this.onPageClick(pageNumber)} activePage={page}/>
          </Col>
        </Row>
        <Row>
          {this.state.isLoading ? 
            <Loading />
            :
            this.state.segments.map((segment) => (
              <Segment key={segment.segment_id} segment={segment} />
            ))
          }
        </Row>
        
      </div>
    );
  }
}