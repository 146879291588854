import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Cookies from 'js-cookie';

import Login from '../Login';
import Home from '../Home';
import Segmentation from '../Segmentation';
import SegmentAction from '../SegmentAction';
import SegmentEdit from '../SegmentEdit';
import ActionHistory from '../ActionHistory';
import Schedules from '../Schedules';
import Profile from '../Profile';
import Profiles from '../Profiles';
import CampaignBuilder from '../CampaignBuilder';
import GhostCMS from '../GhostCMS';
import Posts from './../GhostCMS/Posts';
import Tags from './../GhostCMS/Tags';
import Analytics from '../Analytics';
import POSPhotos from '../POS/Settings/POSPhotos';
import OAuthCallback from '../OAuthCallback';
import Users from '../Users';
import UserGroups from '../UserGroups';

import api from '../../api';

// The Main component renders one of the three provided
// Routes (provided that one matches). Both the /roster
// and /schedule routes will match any pathname that starts
// with /roster or /schedule. The / route will only match
// when the pathname is exactly the string "/"
//https://medium.com/@pshrmn/a-simple-react-router-v4-tutorial-7f23ff27adf

// For loggin handling, use component={CheckForLogIn}
// This ensure that all the pages required log in will be run AFTER our login logic
// https://medium.com/the-many/adding-login-and-authentication-sections-to-your-react-or-react-native-app-7767fd251bd1

const PrivateRoute = ({component: Component, authed, ...rest}) => {
  return (
    <Route
      {...rest}
      render={(props) => authed === true
        ? <Component {...props} {...rest} />
        : <Redirect to={{pathname: '/login'}} />}
    />
  )
}

export default class Main extends React.Component {
  constructor(props) {
    super(props);
    const access_token = Cookies.get('access_token');
    if (access_token) {
      this.authed = true;
    } else {
      // alert('Please login');
      this.authed = false;
    }
    this.state = {
      currentUser: null,
    }
  }
  componentDidMount() {
    this.getUserInfo();
  }
  async getUserInfo() {
    const access_token = await Cookies.get('access_token');
    if (access_token) {
      this.authed = true;
      api.fetchAPI({
        method: 'get',
        uri: `user/me`,
        success: (response) => {
          if (response.status === 'ok') {
            this.authed = true;
            this.setState({
              currentUser: response.user
            });
          } else {
            this.authed = false;
            alert(response.data);
          }
        },
      });
    } else {
      this.authed = false;
    }
  }
  render() {
    const authed = this.authed;
    const { currentUser } = this.state;
    return (
      <main>
        <Switch>
          <Route exact path='/login' component={Login}/>
          <Route exact path='/oauth_callback' component={OAuthCallback}/>
          <PrivateRoute authed={authed} exact path='/' component={Home} currentUser={currentUser} />
          <PrivateRoute authed={authed} path='/segmentation' component={Segmentation}/>
          <PrivateRoute authed={authed} path='/segment/:segment_id/actions' component={SegmentAction}/>
          <PrivateRoute authed={authed} path='/segment/:segment_id/edit' component={SegmentEdit}/>
          <PrivateRoute authed={authed} path='/segment/:segment_id/action/:action_id/history' component={ActionHistory} />
          <PrivateRoute authed={authed} path='/segment/:segment_id/builder' component={CampaignBuilder}/>
          <PrivateRoute authed={authed} path='/schedules' component={Schedules}/>
          <PrivateRoute authed={authed} path='/profiles' component={Profiles}/>
          <PrivateRoute authed={authed} path='/profile/:brand/:barcode' component={Profile}/>
          <PrivateRoute authed={authed} path='/newsfeeds/' component={GhostCMS}/>
          <PrivateRoute authed={authed} path='/newsfeed/posts' component={Posts}/>
          <PrivateRoute authed={authed} path='/newsfeed/tags' component={Tags}/>
          <PrivateRoute authed={authed} path='/analytics' component={Analytics}/>
          <PrivateRoute authed={authed} path='/pos/settings/photos' component={POSPhotos}/>
          <PrivateRoute authed={authed} path='/users' component={Users} currentUser={currentUser} />
          <PrivateRoute authed={authed} path='/user_groups' component={UserGroups} currentUser={currentUser} />
        </Switch>
      </main>
    );
  }
  
};
