import React from 'react';
import './index.css';
import Loading from '../../components/Loading';
import api from '../../api';

export default class ActionHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      brand: this.props.match.params.brand,
      barcode: this.props.match.params.barcode,
      isLoading: false,
    }
  }
  componentDidMount = () => {
    document.title = "Profile | TCH Maria";
    this.getCustomerProfile();
  }
  getCustomerProfile = () => {
    this.setState({ isLoading: true });
    const { brand, barcode } = this.state;
    api.fetchAPI({
        method: 'get',
        uri: `profile/${brand}/${barcode}`,
        success: (response) => {
          console.log(response)
          this.setState({
            profile: response.data,
            isLoading: false,
          });
        }
    });
  }
  render() {
    const { profile } = this.state;
    let profileKeys = [];

    if (profile) {
      for (let key in profile) {
        profileKeys.push({
          'attribute_name': key,
          'attribute_value': profile[key],
        });
      }
    }

    return (
      <div className="main">
        {this.state.isLoading ?
          <Loading />
          :
          <div className="customer-info-detail">
            {profile ? 
              <div>
                <h4 className="customer-name">{profile.name} <span className={`customer-brand ${profile.brand}`}>{profile.brand}</span></h4>
                {profileKeys.map(attribute => (
                  <p>
                    <span className="customer-attribute-name">{attribute.attribute_name}</span>
                    <span className="customer-attribute-value">{attribute.attribute_value}</span>
                  </p>
                ))}
              </div>
              :
              null
            }
          </div>
        }
        
      </div>
    );
  }
}