import React from 'react';
import { Button, } from 'reactstrap';
import './index.css';
import api from '../../../api';

export default class NewsFeed extends React.Component {
    constructor(props) {
        super(props);
        
        // Tag pre-processing
        let actionConfigInit = {}
        if (!this.props.action.action_config) {
            actionConfigInit = {
                tags: []
            }
        }
        else {
            actionConfigInit = this.props.action.action_config;
        }

        // Set default state
        this.state = {
            action: {
                action: '',
                name: '',
                description: '',
                is_deleted: 0,
            },
            tagList: [],
            actionConfig: actionConfigInit,
            editMode: this.props.action.action === 'create' ? true : false,
            isSending: false,
        };
        
    }
    componentDidMount() {
        this.setState({ action: this.props.action });
        this.getNewsFeedTags();
    }
    getNewsFeedTags = () => {
        this.setState({ isSending: true });
        api.fetchAPI({
            method: 'get',
            uri: 'newsfeed/tags',
            success: (response) => {
                if (response.status === 'ok') {
                    this.setState({ tagList: response.data, isSending: false });
                }
                else {
                    alert(response.data.message);
                }
            },
            error: (error) => {
                this.setState({ isSending: false });
                alert(error)
            }
        });
    }
    selectTag = (tag_id) => {
        let actionConfig = this.state.actionConfig;

        const idx = actionConfig.tags.indexOf(tag_id);
        if (idx === -1) {
            actionConfig.tags.push(tag_id);
        }
        else {
            actionConfig.tags.splice(idx, 1);
        }
        
        this.setState({ actionConfig });
        this.props.updateConfig(actionConfig);
    }
    render() {
        return (
            <div>
                <h4>News feed config</h4>
                <div>
                    <p>Tags</p>
                    {this.state.tagList && this.state.tagList.length > 0 ? 
                        this.state.tagList.map((tag) =>
                            <Button
                                key={tag.tag_id ? tag.tag_id : Math.random()}
                                color={this.state.actionConfig && this.state.actionConfig.tags.indexOf(tag.tag_id) > -1 ? 'primary': 'default'}
                                size="sm"
                                onClick={() => this.selectTag(tag.tag_id)}
                            >
                                {tag.name}
                            </Button>
                        )
                        :
                        <div />
                    }
                </div>
            </div>
        );
    }
}