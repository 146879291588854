import React from 'react';
import { FormGroup, Input, Label } from 'reactstrap';
import './index.css';

export default class Email extends React.Component {
    constructor(props) {
        super(props);
        
        // Tag pre-processing
        let actionConfigInit = {}
        if (!this.props.action.action_config) {
            actionConfigInit = {
                title: '',
                content_text: '',
                content_html: '',
            }
        }
        else {
            actionConfigInit = this.props.action.action_config;
        }

        // Set default state
        this.state = {
            action: {
                action: '',
                name: '',
                description: '',
                is_deleted: 0,
            },
            actionConfig: actionConfigInit,
            editMode: this.props.action.action === 'create' ? true : false,
            isSending: false,
        };
    }
    editActionValue = ({ property, value }) => {
        const actionConfig = this.state.actionConfig;
        actionConfig[property] = value;
        this.setState({ actionConfig });
        this.props.updateConfig(actionConfig);
    }
    componentDidMount() {
        this.setState({ action: this.props.action });
    }
    render() {
        return (
            <div>
                <h4>Email config</h4>
                <div>
                    <FormGroup>
                        <Label>Title</Label>
                        <Input type="text" name="text" placeholder="Title" value={this.state.actionConfig.title} onChange={(e) => this.editActionValue({ property: 'title', value: e.target.value })} />
                    </FormGroup>
                    <FormGroup>
                        <Label>Content Text</Label>
                        <Input type="textarea" name="text" placeholder="Content" value={this.state.actionConfig.content_text} onChange={(e) => this.editActionValue({ property: 'content_text', value: e.target.value })} />
                    </FormGroup>
                    <FormGroup>
                        <Label>Content HTML</Label>
                        <Input type="textarea" name="text" placeholder="Content" value={this.state.actionConfig.content_html} onChange={(e) => this.editActionValue({ property: 'content_html', value: e.target.value })} />
                    </FormGroup>
                </div>
            </div>
        );
    }
}