import React from 'react';
import numeral from 'numeral';
import FunnelGraph from 'funnel-graph-js';

import Loading from '../../components/Loading';
import api from '../../api';
import History from '../ActionHistory/History';
import './index.css';
export default class ActionHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        history: [],
        segment_id: this.props.match.params.segment_id,
        action_id: this.props.match.params.action_id,
        isLoading: false,
    }
  }
  componentDidMount = () => {
    document.title = "Action run history | TCH Maria";
    this.loadHistory();
  }
  getActionStats() {
    api.fetchAPI({
      method: 'get',
      uri: `action/${this.state.action_id}/stats`,
      success: (response) => {
        if (response.status === 'ok') {
          const stats = response.data;
          console.log('xxxx', stats);
          const statsTransformed = {
            labels: ['Gửi noti', 'Đọc noti', 'Mua hàng'],
            colors: ['#0066ff', '#33cccc'],
            values: [stats.user_send, stats.user_read, stats.user_redeem]
          };

          this.setState({ stats, statsTransformed });
        }
      },
      error: (error) => {
        this.setState({ isSending: false });
        alert(error)
      }
    });
  }
  loadHistory = () => {
    this.setState({ isLoading: true });
    api.fetchAPI({
        method: 'get',
        uri: `segment/${this.state.segment_id}/actions/${this.state.action_id}/history`,
        success: (response) => {
          console.log(response);
          this.setState({
            action: response.data.action,
            history: response.data.history,
            isLoading: false,
          }, this.getActionStats);
        }
    });
    this.setState({ isLoading: false });
  }
  componentDidUpdate() {
    const { statsTransformed } = this.state;
    if (!statsTransformed) return;
    const graph = new FunnelGraph({
      container: '.action-funnel-chart',
      gradientDirection: 'horizontal',
      data: statsTransformed,
      displayPercent: true,
      direction: 'horizontal'
    });
    graph.draw();
  }
  render() {
    const { stats, action } = this.state;
    return (
      <div className="main">
        {this.state.isLoading || !(action) ?
          <Loading />
          :
          <div className="action-history__container-page">
            <h3>{action.name}</h3>
            {stats ?
              <div className="action-stats">
                <div className="action-stats-listing">
                  <p><b>Số người đã gửi</b>: <span className="action-stats-number">{numeral(stats.user_send).format('0,0')}</span></p>
                  <p><b>Số người đã đọc</b>: <span className="action-stats-number">{numeral(stats.user_read).format('0,0')}</span></p>
                  <p><b>Số người đã mua</b>: <span className="action-stats-number">{numeral(stats.user_redeem).format('0,0')}</span></p>
                  <p><b>Số đơn hàng dùng coupon</b>: <span className="action-stats-number">{numeral(stats.orders).format('0,0')}</span></p>
                  <p><b>Net sales không VAT</b>: <span className="action-stats-number">{numeral(stats.sales).format('0,0')} đ</span></p>
                  <p><b>Marginal Profit</b>: <span className="action-stats-number">{numeral(stats.marginal_profit).format('0,0')} đ</span></p>
                </div>
                <div className="action-funnel-chart-container">
                  <div className="action-funnel-chart" />
                </div>
              </div>
              :
              <div>
                <p>Loading action statistics...</p>
              </div>
            }
            <h4>History</h4>
            {this.state.history.map((history) => 
              <History history={history} key={history.log_id} />
            )}
          </div>
        }
        
      </div>
    );
  }
}