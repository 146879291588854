import React from 'react';
import { Button, Table } from 'reactstrap';
import numeral from 'numeral';
import api from '../../api';
import './index.css';

import "react-datepicker/dist/react-datepicker.css";

const dashboard_links = {
  running_campaigns: 'running_campaigns',
  campaign_overview: 'https://datastudio.google.com/embed/reporting/1n6Uwwv30D4VY2EWlMYkQScnUBXPLmoCZ/page/uSCq',
  campaign_detail: 'https://datastudio.google.com/embed/reporting/1n6Uwwv30D4VY2EWlMYkQScnUBXPLmoCZ/page/MfVZ'
}

export default class Analytics extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDashboard: dashboard_links['running_campaigns'],
      campaigns: null,
    }
  }
  componentDidMount = () => {
    document.title = "Newsfeed | TCH Maria";
    this.getRunningCampaignStats();
  }
  getRunningCampaignStats() {
    api.fetchAPI({
      method: 'get',
      uri: `campaigns/stats`,
      success: (response) => {
        if (response.status === 'ok') {
          this.setState({
            campaigns: response.data.stats,
          });
        }
      },
      error: (error) => {
        alert(error);
      }
  });
  }
  render() {
    const { selectedDashboard, campaigns } = this.state;
    return (
      <div className="ghost-cms__container">
        <div className="ghost-cms__navigation">
          <Button color="primary" onClick={() => this.setState({ selectedDashboard: dashboard_links.running_campaigns })}>Running campaigns</Button>{' '}
          <Button color="primary" onClick={() => this.setState({ selectedDashboard: dashboard_links.campaign_overview })}>Campaign overview</Button>{' '}
          <Button color="primary" onClick={() => this.setState({ selectedDashboard: dashboard_links.campaign_detail })}>Campaign detail</Button>
        </div>
        {selectedDashboard.includes('http') &&
          <iframe
            src={selectedDashboard}
            frameBorder="0"
            className="ghost-cms__iframe"
            title="iframe-analytics"
          />
        }
        {campaigns ?
          <Table striped responsive>
            <thead>
              <tr>
                <th>Campaigns</th>
                <th>Segment</th>
                <th>Sent</th>
                <th>Read</th>
                <th>Redeem</th>
                <th>Orders</th>
                <th>NetSales(kVAT)</th>
                <th>Marginal Profit</th>
              </tr>
            </thead>
            <tbody>
              {campaigns.map((campaign, idx) => 
                <tr className={`campaign-stats--row ${campaign.marginal_profit < 0 ? 'campaign-stats--row--loss' : ''}`}
                  key={campaign.id ? campaign.id : idx}
                >
                  <th className="campaign-stats--campaign-name" scope="row">
                    {campaign.segment_id && campaign.action_id ?
                      <a target="_blank" href={`/segment/${campaign.segment_id}/action/${campaign.action_id}/history`}>
                        {campaign.coupon_campaign_name}
                      </a>
                      :
                      <a target="_blank" href={isNaN(campaign.campaign_id) ? `https://admin.thecoffeehouse.com/promotion/campaign/get/${campaign.campaign_id}` : `https://erp.thecoffeehouse.vn/web?#id=${campaign.campaign_id}&view_type=form&model=crm.voucher.publish&menu_id=413&action=585`}>
                        {campaign.coupon_campaign_name}
                      </a>
                    }
                  </th>
                  <td className="campaign-stats--campaign-name">{campaign.segment_name}</td>
                  <td>{numeral(campaign.user_send).format('0,0')}</td>
                  <td>{numeral(campaign.user_read).format('0,0')}</td>
                  <td>{numeral(campaign.user_redeem).format('0,0')}</td>
                  <td>{numeral(campaign.orders).format('0,0')}</td>
                  <td>{numeral(campaign.sales).format('0,0')}</td>
                  <td>{numeral(campaign.marginal_profit).format('0,0')}</td>
                </tr>
              )}
            </tbody>
          </Table>
          :
          <p>Loading, chờ xíu nha ^^...</p>
        }
      </div>
    );
  }
}