import React from 'react';
import { FormGroup, Input, Label, Button, Card } from 'reactstrap';
import './index.css';

export default class ButtonActionSettings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            buttonsConfig: this.props.buttonsConfig,
            screen: '',
            color: '',
            backgroundColor: '',
            couponCode: this.props.couponCode || null,
        };
    }
    componentWillMount() {
        // this.setState({ action: this.props.action });
    }
    editActionValue = ({ property, value }) => {
        const state = this.state;

        let valueFormatted = value;
        if (property == 'url') {
            if (!value.includes('?mobile=1')) {
                valueFormatted = valueFormatted + '?mobile=1';
            }
        }
        state[property] = valueFormatted;
        this.setState(state);
    }
    addNotificationButtons = () => {

        const {
            createNewButtonText, color, backgroundColor, screen, url, coupon,
            overlayButtonScreen, overlayButtonTitle, couponCode,
        } = this.state;

        const newButton = {
            title: createNewButtonText,
            action: [
                {
                    type: 'NAV_SCREEN',
                    payload: {
                        screen: screen,
                    },
                },
            ],
        };

        // If generic coupon code is specify, add it to order screen
        if (couponCode && couponCode !== '') {
            newButton.action.push({
                type: 'SET_COUPON',
                payload: couponCode,
            });
        }

        if (color && color !== '') {
            newButton.color = color;
        }
        if (backgroundColor && backgroundColor !== '') {
            newButton.style = `{"backgroundColor":"${backgroundColor}"}`;
        }
        if (url) {
            newButton.action[0].payload.props = { url };
        }
        if (coupon) {
            newButton.action[0].payload.props = { coupon };
        }

        // Show button on news page
        if (screen === 'WebBrowser' && overlayButtonScreen && overlayButtonTitle) {
            const webBrowerOverlayProps = {
                url,
                button: {
                    title: overlayButtonTitle,
                    actions: [
                        {
                            type: overlayButtonScreen.toLowerCase() === 'order' ? 'NAV_RESET_SCREEN' : 'NAV_SCREEN',
                            payload: {
                                screen: overlayButtonScreen,
                            },
                        },
                    ],
                },
            }
            // If generic coupon code is specify, add it to order screen
            if (couponCode && couponCode !== '') {
                webBrowerOverlayProps.button.actions.push({
                    type: 'SET_COUPON',
                    payload: couponCode,
                });
            }
            newButton.action[0].payload.props = webBrowerOverlayProps;
        }
        console.log(JSON.stringify(newButton));
        if (this.props.onButtonCreated) {
            this.props.onButtonCreated(newButton);
        }
    }
    render() {
        const screen = this.state.screen;
        const { color, backgroundColor, createNewButtonText } = this.state;
        let configComponent = <div/>;
        switch(screen) {
            case 'Order':
            case 'Coupon':
                configComponent = (
                    <FormGroup>
                        <Label>Coupon code</Label>
                        <Input
                            type="text"
                            name="text"
                            placeholder="Input coupon code here"
                            value={this.state.coupon ? this.state.coupon : ''}
                            onChange={(e) => this.editActionValue({ property: 'coupon', value: e.target.value })}
                        />
                    </FormGroup>
                );
                break;
            case 'WebBrowser':
                configComponent = (
                    <div>
                        <FormGroup>
                            <Label>Link to web</Label>
                            <Input
                                type="text"
                                name="text"
                                placeholder="https://somethingamazing.com"
                                value={this.state.url ? this.state.url : ''}
                                onChange={(e) => this.editActionValue({ property: 'url', value: e.target.value })}
                            />
                        </FormGroup>
                        <h5>Cấu hình nút overlay trên news</h5>
                        <div className="overlay-button-config__container">
                            <FormGroup>
                                <Label>Tiêu đề nút overlay</Label>
                                <Input
                                    type="text"
                                    name="text"
                                    placeholder="Đặt một tên nào đó đừng dài quá"
                                    value={this.state.overlayButtonTitle ? this.state.overlayButtonTitle : ''}
                                    onChange={(e) => this.editActionValue({ property: 'overlayButtonTitle', value: e.target.value })}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label>Bấm vào nút này thì đi đâu</Label>
                                <Input
                                    type="select"
                                    value={this.state.overlayButtonScreen ? this.state.overlayButtonScreen : ''}
                                    onChange={(e) => this.editActionValue({ property: 'overlayButtonScreen', value: e.target.value })}
                                >
                                    <option>-- Chọn 1 screen</option>
                                    <option value="Order">Order</option>
                                    <option value="WebBrowser">WebBrowser (News)</option>
                                    <option value="Coupon">Coupon</option>
                                    <option value="Mission">Mission</option>
                                    <option value="Profile">Profile</option>
                                    <option value="Store">Store</option>
                                    <option value="Rewards">Rewards</option>
                                </Input>
                            </FormGroup>
                        </div>
                    </div>
                );
                break;
            default:
                configComponent  = (<div/>)
        }
        
        return (
            <Card body inverse color="info">
                <h4>Button builder</h4>
                <FormGroup>
                    <Label>Button title</Label>
                    <Input type="text" name="text" placeholder="Button title" value={this.state.createNewButtonText} onChange={(e) => this.setState({ createNewButtonText: e.target.value })} />

                    <Label>Screen</Label>
                    <Input
                        type="select"
                        value={this.state.screen ? this.state.screen : ''}
                        onChange={(e) => this.editActionValue({ property: 'screen', value: e.target.value })}
                    >
                        <option>-- Chọn 1 screen</option>
                        <option value="Order">Order</option>
                        <option value="WebBrowser">WebBrowser (News)</option>
                        <option value="Coupon">Coupon</option>
                        <option value="Mission">Mission</option>
                        <option value="Profile">Profile</option>
                        <option value="Store">Store</option>
                        <option value="Rewards">Rewards</option>
                    </Input>
                    {configComponent}

                    <Label>Background color</Label>
                    <Input type="text" name="text" placeholder="Hexa, or color name" value={this.state.backgroundColor} onChange={(e) => this.setState({ backgroundColor: e.target.value })} />

                    <Label>Text color</Label>
                    <Input type="text" name="text" placeholder="Hexa, or color name" value={this.state.color} onChange={(e) => this.setState({ color: e.target.value })} />
                </FormGroup>
                <Button
                    color="warning"
                    onClick={() => this.addNotificationButtons()}>
                    Create button
                </Button>
                <div className="preview-container">
                    <Button
                        style={{
                            color: color ? color: '#191919',
                            backgroundColor: backgroundColor ? backgroundColor : '#ea8025',
                            borderColor: color,
                            paddingVertical: 9,
                            paddingHorizontal: 18,
                            borderRadius: 18,
                        }}
                    >
                        {createNewButtonText ? createNewButtonText : 'Preview nút của bạn'}
                    </Button>
                </div>
            </Card>
        );
    }
}