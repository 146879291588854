import React from 'react';
import './index.css';
import Action from '../../components/Action';
import api from '../../api';

export default class Schedules extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        actions: [],
        segment_id: this.props.match.params.segment_id,
    }
  }
  componentDidMount = () => {
    document.title = "Schedule Actions | TCH Maria"
    api.fetchAPI({
        method: 'get',
        uri: `actions/schedule`,
        success: (response) => {
            console.log(response);
            this.setState({ actions: response.data.actions });
        }
    })
  }
  render() {
    return (
      <div className="main">
        <h4>Actions on schedule</h4>
        {this.state.actions.map((action) => {
            return (
                <Action key={action.schedule_job_id} action={action} display_mode="schedule"/>
            )
        })}
      </div>
    );
  }
}