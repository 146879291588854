import React from 'react';
import { FormGroup, Input, Label, Button, Card, Alert } from 'reactstrap';
import './index.css';

export default class DeepLink extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            deeepLinkConfig: {
                screen: 'Order',
                coupon_code: this.props.coupon_code ? this.props.coupon_code: ''
            },
            deepLink: '',
            brand: this.props.brand || 'tch',
        };
    }
    componentWillMount() {
        // this.setState({ action: this.props.action });
    }
    editActionValue = ({ property, value }) => {
        const deeepLinkConfig = this.state.deeepLinkConfig;
        deeepLinkConfig[property] = value;
        this.setState({ deeepLinkConfig });
    }
    buildDeepLink = () => {
            let baseDeepLinkUrl = 'https://www.thecoffeehouse.com';
            if (this.state.brand === 'tch') {
                baseDeepLinkUrl = 'https://www.thecoffeehouse.com';
            }
            else if (this.state.brand === 'tenren') {
                baseDeepLinkUrl = 'https://order.tenrenvietnam.com';
            }
            else {
                baseDeepLinkUrl = '';
            }

            const screen = this.state.deeepLinkConfig.screen;

            // Construc deep link params according to the selected screen
            let params = {}
            if (screen === 'Order' || screen === 'Coupon') {
                params = {
                    coupon: this.state.deeepLinkConfig.coupon_code,
                };
            }
            else if (screen === 'WebBrowser') {
                params = {
                    url: this.state.deeepLinkConfig.web_view_url,
                };
            }

            // Build final deep link
            const params_json = JSON.stringify(params);
            const base64params = new Buffer(params_json).toString('base64');
            const deepLink = `${baseDeepLinkUrl}?screen=${screen}&params=${base64params}`;

            this.setState({ deepLink });
    }
    render() {
        // https://www.thecoffeehouse.com?screen=Order&params=eyJjb3Vwb24iOiJCQU5BTkhTT04ifQ==
        const screen = this.state.deeepLinkConfig.screen;
        let configComponent = <div/>;
        switch(screen) {
            case 'Order':
            case 'Coupon':
                configComponent = (
                    <FormGroup>
                        <Label>Coupon code</Label>
                        <Input
                            type="text"
                            name="text"
                            placeholder="Input coupon code here"
                            value={this.state.deeepLinkConfig.coupon_code ? this.state.deeepLinkConfig.coupon_code : ''}
                            onChange={(e) => this.editActionValue({ property: 'coupon_code', value: e.target.value })}
                        />
                    </FormGroup>
                );
                break;
            case 'WebBrowser':
                configComponent = (
                    <FormGroup>
                        <Label>Link to web</Label>
                        <Input
                            type="text"
                            name="text"
                            placeholder="https://somethingamazing.com"
                            value={this.state.deeepLinkConfig.web_view_url ? this.state.deeepLinkConfig.web_view_url : ''}
                            onChange={(e) => this.editActionValue({ property: 'web_view_url', value: e.target.value })}
                        />
                    </FormGroup>
                );
                break;
            default:
                configComponent  = (<div/>)
        }
        
        return (
            <Card body inverse style={{ backgroundColor: '#333', borderColor: '#333' }}>
                <FormGroup>
                    <Label>Screen</Label>
                    <Input
                        type="select"
                        value={this.state.deeepLinkConfig.screen ? this.state.deeepLinkConfig.screen : ''}
                        onChange={(e) => this.editActionValue({ property: 'screen', value: e.target.value })}
                    >
                        <option value="Order">Order</option>
                        <option value="WebBrowser">WebBrowser (News)</option>
                        <option value="Coupon">Coupon</option>
                        <option value="Mission">Mission</option>
                        <option value="Profile">Profile</option>
                        <option value="Store">Store</option>
                        <option value="Rewards">Rewards</option>
                    </Input>
                    {configComponent}
                </FormGroup>
                <Button color="success" onClick={()=>this.buildDeepLink()}>Build deep link</Button>
                {this.state.deepLink && this.state.deepLink !== ''?
                    <div className="deep-link-builder-result-container">
                        <p>Your deep link will appear here. Copy it to the Deep link box below and go!</p>
                        <Alert color="light">
                            {this.state.deepLink}
                        </Alert>
                    </div>
                    :
                    <div />
                }
            </Card>
        );
    }
}