import React from 'react';
import { Row, Col, Button, FormGroup, Input, Badge } from 'reactstrap';
import { Link } from 'react-router-dom';
import './index.css';
import api from '../../api';

import Notification from './Notification';
import Email from './Email';
import NewsFeed from './NewsFeed';
import normalizeSegment from '../../resources/normalizers/segment';
import normalizeAction from '../../resources/normalizers/action';

export default class Action extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: true,
            action: {
                action: '',
                name: '',
                description: '',
                is_deleted: 0,
                type: '',
                status: '',
            },
            editMode: this.props.action && this.props.action.action === 'create' ? true : false,
            isSending: false,
        };
    }
    componentDidMount() {
        this.setState({ action: this.props.action });
    }
    fetchFullActionData = () => {
        api.fetchAPI({
            method: 'get',
            uri: `segment/${this.state.action.segment_id}/actions/${this.state.action.action_id}`,
            success: (response) => {
                this.setState({ action: response.data.action });
            }
        })
    }
    editAction = ({ isCancel }) => {
        // Only now did we need to extract full data of the action
        api.fetchAPI({
            method: 'get',
            uri: `segment/${this.state.action.segment_id}/actions/${this.state.action.action_id}`,
            success: (response) => {
                this.setState({ action: response.data.action }, () => {
                    let editMode = false;
                    const action = this.state.action;
                    if (this.state.editMode === true) {
                        action.action = '';
                        editMode = false;
                        if (isCancel) {
                            action.is_deleted = 0;
                        }
                        else {
                            action.is_deleted = 1;
                        }
                    }
                    else{
                        action.action = 'update';
                        editMode = true;
                    }

                    this.setState({ editMode, action })
                });
            }
        })
    }
    saveAction = () => {
        let uri = '';
        let method = 'get';
        const { action } = this.state;

        if (action.action === 'create') {
            uri = `segment/${action.segment_id}/actions`;
            method = 'post';
        }
        else if (action.action === 'update') {
            uri = `segment/${action.segment_id}/actions/${action.action_id}`;
            method = 'post';
        }
        else {
            uri = '';
        }
        this.setState({ isSending: true });
        api.fetchAPI({
            method: method,
            uri: uri,
            body: action,
            success: (response) => {
                if (response.status === 'ok') {
                    action.action_id = response.data.action_id && response.data.action_id > 0 ? response.data.action_id : action.action_id;
                    if (response.data.action) {
                        this.setState({ action: response.data.action, isSending: false, editMode: false })
                    } else {
                        this.setState({ editMode: false, isSending: false, action });
                    }
                }
                else {
                    alert(response.data.message);
                }
            },
            error: (error) => {
                this.setState({ isSending: false });
                alert(error);
            }
        })
    }
    editActionValue = ({ property, value }) => {
        const { action } = this.state;
        action[property] = value;
        this.setState({ action });
    }
    updateConfig = (actionConfig) => {
        const { action } = this.state;
        action.action_config = actionConfig;
        this.setState({ action });
    }
    runNow = (action_type) => {
        const { action } = this.state;
        let r = false;
        //eslint-disable-next-line
        r = confirm(`Are you sure you to ${action_type} "${action.name}"'?`);

        if (r === true) {
            this.setState({ isSending: true });
            api.fetchAPI({
                method: 'get',
                uri: `segment/${action.segment_id}/actions/${action.action_id}/${action_type}`,
                success: (response) => {
                    if (response.status === 'ok') {
                        console.log(response.data.action);
                        if (response.data.action) {
                            if (action_type === 'disable_schedule') {
                                this.setState({ action: response.data.action });
                            } else if (action_type === 'delete') {
                                this.setState({ action: null });
                            } else {
                                this.setState({ action: response.data.action });
                            }
                        }
                        this.setState({ isSending: false });
                        if (action_type == 'run') {
                            alert('This action has been submitted to run');
                        }
                    }
                    else {
                        alert(response.data.message);
                    }
                },
                error: (error) => {
                    this.setState({ isSending: false });
                    alert(error)
                }
            });
        }
    }
    moveToAnotherSegment = () => {
        api.fetchAPI({
            method: 'get',
            uri: `segments?order_by=created_at&order_type=desc&brand=${this.state.action.segment.brand}&limit=1000&page=1`,
            success: (response) => {
                this.setState({
                    segments: response.data.segments.map(normalizeSegment),
                    moveToAnotherSegmentSelection: !this.state.moveToAnotherSegmentSelection,
                });
            }
        });
    }
    confirmMoveToAnotherSegment = () => {
        if (this.state.moveToAnotherSegmentId) {
            api.fetchAPI({
                method: 'get',
                uri: `segment/${this.state.action.segment_id}/actions/${this.state.action.action_id}/move_to_segment?move_to_segment_id=${this.state.moveToAnotherSegmentId}`,
                success: (response) => {
                    if (response.status === 'ok') {
                        // Load the moved segment page
                        // Remove this action from segment listing
                        this.setState({ visible: false });
                    }
                    else {
                        alert(response.data.message);
                    }
                },
                error: (error) => {
                    this.setState({ isSending: false });
                    alert(error)
                }
            });
        }
        else {
            alert('Please select a segment to move to');
        }
    }
    render() {
        const { visible, action, editMode } = this.state;
        if (visible === false) {
            return null;
        }
        let actionTypeColor = 'default';
        let actionTypeComponent = (<div/>);
        if (!action) {
            return null;
        };
        switch(action.type) {
            case 'notification':
            case 'notification_onesignal':
                actionTypeColor = 'primary';
                actionTypeComponent = (
                    <Notification
                        action={action}
                        editMode={editMode}
                        updateConfig={this.updateConfig}
                    />
                );
                break;
            case 'newsfeed':
                actionTypeColor = 'danger';
                actionTypeComponent = (
                    <NewsFeed 
                        action={this.state.action}
                        editMode={this.state.editMode}
                        updateConfig={this.updateConfig}
                    />
                )
                break;
            case 'email':
                actionTypeColor = 'success';
                actionTypeComponent = (
                    <Email 
                        action={action}
                        editMode={editMode}
                        updateConfig={this.updateConfig}
                    />
                )
                break;
            case 'graph':
            case 'segment_snap':
                actionTypeColor = 'warning';
                break;
            default:
                actionTypeColor = 'default';
        }
        console.log(action);
        return (
            <div>
                {action.is_deleted === 1 ?
                    <div />
                :
                    <div className="segment">
                        {editMode === false ? 
                            <div>
                                { this.props.display_mode !== 'action' ?
                                    <p className="segment-title">Segment: {action.segment ? action.segment.name : 'Unknown segment'}</p>
                                    :
                                    <span/>
                                }
                                <Row>
                                    <Col xs="12" sm="4" md="3">
                                        <p>{action.name}</p>
                                    </Col>
                                    <Col xs="12" sm="4" md="3">
                                        <p>{action.description}</p>
                                    </Col>
                                    <Col xs="6" sm="4" md="3">
                                        <Badge color={actionTypeColor}>{action.type}</Badge>
                                    </Col>
                                    <Col xs="6" sm="4" md="3">
                                        <Badge color={action.status == 'draft' ? 'warning' : 'success'}>{action.status}</Badge>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs="12">
                                        <Button color="primary" className="segment-action" tag={Link} to={`/segment/${this.state.action.segment_id}/action/${this.state.action.action_id}/history`}>History</Button>
                                        <Button color="info" className="segment-action" onClick={()=>this.editAction({ isCancel: false })}>Edit</Button>
                                        {action.type && action.status === 'approved' ?
                                            <Button color="danger" className="segment-action" disabled={this.state.isSending ? true : false} onClick={()=>this.runNow('run')}>Run Now</Button>
                                            :
                                            <span />
                                        }
                                        <Button outline color="danger" className="segment-action" disabled={this.state.isSending ? true : false} onClick={()=>this.runNow('delete')}>Delete</Button>
                                        {action.cron_string && action.schedule_job_id &&
                                            <Button color="warning" className="segment-action" disabled={this.state.isSending ? true : false} onClick={()=>this.runNow('disable_schedule')}>Disable schedule</Button>
                                        }
                                        <Button outline color="primary" className="segment-action" disabled={this.state.isSending ? true : false} onClick={()=>this.moveToAnotherSegment()}>Move to another segment</Button>
                                        {action.permissions && action.permissions.can_approve && action.status == 'draft' &&
                                            <Button color="success" className="segment-action" disabled={this.state.isSending ? true : false} onClick={()=>this.runNow('approve')}>Approve</Button>
                                        }
                                    </Col>
                                </Row>
                                {this.state.moveToAnotherSegmentSelection ?
                                    <Row>
                                        <Col xs="12" sm="4">
                                            <Input
                                                type="select"
                                                value={this.state.moveToAnotherSegmentId}
                                                onChange={(e) => this.setState({ moveToAnotherSegmentId: e.target.value })}
                                            >
                                                <option value="">-- Select a segment --</option> 
                                                {this.state.segments.map((segment) => 
                                                    <option value={segment.segment_id}>{segment.name}</option>    
                                                )}
                                            </Input>
                                            <Button outline color="primary" className="segment-action" disabled={this.state.isSending ? true : false} onClick={()=>this.confirmMoveToAnotherSegment()}>Move</Button>
                                        </Col>
                                    </Row>
                                    :
                                    null
                                }
                            </div>
                            :
                            <div>
                                <Row>
                                    <Col xs="6" sm="4">
                                        <FormGroup>
                                            <Input
                                                type="text"
                                                value={this.state.action.name}
                                                onChange={(e) => this.editActionValue({ property: 'name', value: e.target.value })}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col xs="6" sm="4">
                                        <FormGroup>
                                            <Input
                                                type="text"
                                                value={this.state.action.description ? this.state.action.description : ''}
                                                onChange={(e) => this.editActionValue({ property: 'description', value: e.target.value })}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col xs="12" sm="4">
                                        <Input
                                            type="select"
                                            value={this.state.action.type ? this.state.action.type : 'newsfeed'}
                                            onChange={(e) => this.editActionValue({ property: 'type', value: e.target.value })}
                                        >
                                            <option value="notification">notification</option>
                                            <option value="notification_onesignal">notification_onesignal</option>
                                            <option value="email">email</option>
                                            <option value="newsfeed">newsfeed</option>
                                            <option value="segment_snap">segment_snap</option>
                                            <option value="graph">graph</option>
                                            <option value="test_no_run">test_no_run</option>
                                        </Input>
                                    </Col>
                                    <Col xs="12" sm="4">
                                        <FormGroup>
                                            <Input
                                                type="text"
                                                row={2}
                                                placeholder="Hẹn giờ"
                                                value={this.state.action.cron_string ? this.state.action.cron_string : ''}
                                                onChange={(e) => this.editActionValue({ property: 'cron_string', value: e.target.value })}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col xs="12" sm="4">
                                        <FormGroup>
                                            <Input
                                                type="text"
                                                row={2}
                                                placeholder="Campaign ID"
                                                value={this.state.action.campaign_id ? this.state.action.campaign_id : ''}
                                                onChange={(e) => this.editActionValue({ property: 'campaign_id', value: e.target.value })}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col xs="12" sm="4">
                                        <FormGroup>
                                            <Input
                                                type="text"
                                                row={2}
                                                placeholder="Coupon code"
                                                value={this.state.action.coupon_code ? this.state.action.coupon_code : ''}
                                                onChange={(e) => this.editActionValue({ property: 'coupon_code', value: e.target.value })}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                {actionTypeComponent}
                                <Button color="success" className="segment-action" disabled={this.state.isSending ? true : false} onClick={()=>this.saveAction()}>Save</Button>
                                <Button color="danger" className="segment-action" disabled={this.state.isSending ? true : false} onClick={()=>this.editAction({ isCancel: true })}>Cancel</Button>
                            </div>
                        }
                    </div>
                }
            </div>
        );
    }
}