import React from 'react';
import { Button, FormGroup, Label, Input } from 'reactstrap';
import Cookies from 'js-cookie';
import ClientOAuth2 from 'client-oauth2';

import api from '../../api';
import Constant from '../../constants';

import './index.css';

const hrwAuth = new ClientOAuth2({
  clientId: Constant.HRWConstant.clientId,
  clientSecret: Constant.HRWConstant.clientSecret,
  accessTokenUri: Constant.HRWConstant.accessTokenUri,
  authorizationUri: Constant.HRWConstant.authorizationUri,
  redirectUri: Constant.HRWConstant.redirectUri,
  scopes: Constant.HRWConstant.scopes,
  grant_type: Constant.HRWConstant.grant_type,
});

//http://local.maria.tch.vn:3000/?code=c8ab066b1f8ce3940d7309a9899d1adbc8c621e95bfe627d56f4e2c64fab0e7b&scope=hr_api
export default class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {
        email: '',
        password: '',
      },
    }
  }
  componentDidMount = () => {
    document.title = "Login | TCH Maria";
    const access_token = Cookies.get('access_token');
    if (access_token) {
      // this.props.history.push('/');
    }
    if (this.props.match.params.code) {
      this.callback();
    }
  }
  hrwLogin() {
    const authUrl = hrwAuth.code.getUri();
    console.log(authUrl);
    window.location.href = authUrl;
  }
  login() {
    api.fetchAPI({
      method: 'post',
      serviceBaseUrl: Constant.tchAdminBaseUrl,
      uri: 'auth/login',
      body: this.state.user,
      success: async (response) => {
        if (response.code === 200) {
          // Login success
          const email = response.data.user.email;
          const token = response.data.user.token;
          await Cookies.set('access_token', token, { expires: 1 });

          // Request extra information
          api.fetchAPI({
            method: 'get',
            serviceBaseUrl: Constant.tchAdminBaseUrl,
            uri: 'user/profile',
            success: async (profile) => {
              console.log('Profile')
              console.log(profile)
              const status = profile.data.status;
              if (status === true) {
                // Everything is ok
                // Move to home
                await Cookies.set('access_token', token, { expires: 1 });
                await Cookies.set('email', email, { expires: 1 });
                await Cookies.set('user_id', response.data.id, { expires: 1 });
                
                // Refresh current page to get the newly logged in user
                window.location = '/';
              }
              else {
                alert('Login failure: This account has been suspended');
                await Cookies.remove('access_token');
                await Cookies.remove('email');
                await Cookies.remove('user_id');
              }
            },
            error: async (error_profile) => {
              alert('Login failure', 'Cannot get user profile');
            }
          });
        }
        else {
          alert(`Login failure: ${response.message}`);
        }
      },
      error: (error) => {
        alert('Login failure. Cannot call to server!')
      }
    })
  }
  editUserValue = ({ property, value }) => {
    const user = this.state.user;
    user[property] = value ? value : '';
    this.setState({ user });
  }
  render() {
    return (
      <div className="main-login-form">
        {/* <FormGroup>
          <Label>Email</Label>
          <Input value={this.state.user.email} type="email" placeholder="hello@thecoffeehouse.vn" onChange={(e) => this.editUserValue({ property: 'email', value: e.target.value })} />
        </FormGroup>
        <FormGroup>
          <Label>Password</Label>
          <Input value={this.state.user.password} type="password" placeholder="Password" onChange={(e) => this.editUserValue({ property: 'password', value: e.target.value })} />
        </FormGroup>
        <FormGroup>
          <Button color="primary" onClick={() => this.login()}>Login</Button>
        </FormGroup> */}
        <FormGroup>
          <Button color="primary" onClick={() => this.hrwLogin()}>Login with HaraWorks</Button>
        </FormGroup>
      </div>
    );
  }
}