import React from 'react';
import { Button, FormGroup, Label, Input } from 'reactstrap';
import Cookies from 'js-cookie';
import jwt from 'jsonwebtoken';

import api from '../../api';
import Constant from '../../constants';

import './index.css';

//http://local.maria.tch.vn:3000/?code=c8ab066b1f8ce3940d7309a9899d1adbc8c621e95bfe627d56f4e2c64fab0e7b&scope=hr_api
export default class OAuthCallback extends React.Component {
  constructor(props) {
    super(props);
    const query = new URLSearchParams(this.props.location.search);
    this.state = {
      oauthCode: query.get('code') || null,
      error: '',
    };
  }
  componentDidMount() {
    if (this.state.oauthCode) {
      this.oauth2Login();
    }
  }
  oauth2Login() {
    const submitData = new URLSearchParams();
    submitData.append('code', this.state.oauthCode);
    submitData.append('client_id', Constant.HRWConstant.clientId);
    submitData.append('client_secret', Constant.HRWConstant.clientSecret);
    submitData.append('grant_type', Constant.HRWConstant.grant_type);
    submitData.append('redirect_uri', Constant.HRWConstant.redirectUri);
    submitData.append('scope', Constant.HRWConstant.scope_string);

    console.log(submitData)
    console.log(Constant.HRWConstant.hrwBaseUrl)
      
    api.fetchAPI({
      method: 'post',
      serviceBaseUrl: Constant.HRWConstant.hrwBaseUrl,
      uri: 'connect/token',
      body: submitData,
      success: async (response) => {
        if (response.access_token) {
          await Cookies.set('hrw_access_token', response.access_token, { expires: response.expires_in });
          await Cookies.set('hrw_refresh_token', response.refresh_token);
          await Cookies.set('hrw_id_token', response.id_token);

          // Authenticate with Maria
          const authData = {
            oauth_token: response.id_token,
            hrw_access_token: response.access_token,
          };
          api.fetchAPI({
            method: 'post',
            uri: 'user/oauth_login',
            body: authData,
            success: async (response) => {
              if (response.status == 'ok' && response.data.auth.access_token) {
                const authData = response.data.auth;
                await Cookies.set('access_token', authData.access_token, { expires: 1 });
                await Cookies.set('client_id', authData.client_id, { expires: 1 });
                await Cookies.set('user_id', authData.user_id, { expires: 1 });
                
                // Refresh current page to get the newly logged in user
                window.location = '/';
              } else {
                this.setState({
                  error: response.message ? response.message : response.data,
                });
              }
            },
            catch: (error) => {
              this.setState({
                error: error.message ? error.message : error.data,
              });
            }
          });
        } else {
          this.setState({
            error: response.message,
          });
        }
      }
    });
  }
  render() {
    return (
      <div className="main-login-form">
        <p>Logging in...</p>
        <p>{this.state.error}</p>
      </div>
    );
  }
}