import React from 'react';
import { Button, Row, Col, Input, FormGroup } from 'reactstrap';
import { toast } from 'react-toastify';
import './index.css';

import "react-datepicker/dist/react-datepicker.css";
import api from '../../../../api';

export default class POSPhotos extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      brand: 'tch',
      screenName: this.props.match.params.screen_name || 'default_screen',
      posImages: [],
      isUploading: false,
      fileList: [],
    };
  }
  componentDidMount = () => {
    document.title = "POS Photos | TCH Maria";
    this.getAllImages();
  }
  onChangeHandler(event) {
    const fileList = Array.from(event.target.files) || [];
    this.setState({ fileList });
  }
  copyImageLink(image) {
    navigator.clipboard.writeText(image.image_url);
    toast('Link has been copied', { autoClose: 800, className: 'app__success-toast' });
  }
  getAllImages() {
    const { brand, screenName } = this.state;
    api.fetchAPI({
      method: 'get',
      uri: `pos/settings/second_screen_photos?brand=${brand}&screen_name=${screenName}`,
      success: (response) => {
        this.setState({ posImages: response.pos_images });
      },
    });
  }
  async uploadImages() {
    const { fileList } = this.state;
    if (fileList.length > 0) {
      await fileList.map(file => this.uploadImage(file));
    }
  }
  uploadImage(file) {
    const { brand, screenName, posImages, fileList } = this.state;
    const submitData = new FormData();
    submitData.set('user_file', file);
    submitData.set('brand', brand);
    submitData.set('screen_name', screenName);
    this.setState({ isUploading: true });
    api.fetchAPI({
      method: 'post',
      body: submitData,
      uri: `pos/settings/second_screen_photos`,
      success: (response) => {
        if (response.status === 'ok') {
          posImages.unshift(response.data.image);
          const removeIndex = fileList.indexOf(file);
          if (removeIndex > -1) {
            fileList.splice(removeIndex, 1);
          }
          this.setState({ posImages, isUploading: false, fileList });
        } else {
          alert(response.message);
          this.setState({ isUploading: false });
        }
      },
    });
  }
  deleteImage(image) {
    const confirm = window.confirm('Are you sure to delete this image?');
    if (!confirm) return;
    api.fetchAPI({
      method: 'delete',
      uri: `pos/settings/second_screen_photos/${image.image_id}`,
      success: (response) => {
        if (response.status === 'ok') {
          this.getAllImages();
          toast('Image has been deleted', { autoClose: 800, className: 'app__success-toast' });
        } else {
          alert(response.message);
        }
      },
    });
  }
  render() {
    const { brand, screenName, posImages, isUploading, fileList } = this.state;
    return (
      <div className="pos-photos__container">
        <Row>
          <Col xs="12" className="pos-photos__header">
            <h1>POS Screen Settings</h1>
          </Col>
          <Col xs="12" sm="4">
            <FormGroup>
              <Input
                type="select"
                value={brand}
                onChange={(e) => this.setState({ brand: e.target.value }, this.getAllImages)}
              >
                <option value="">-- Select brand --</option> 
                <option value="tch">TCH</option>
                <option value="tenren">Ten Ren</option>
              </Input>
            </FormGroup>
            <FormGroup>
              <Input
                type="select"
                value={screenName}
                onChange={(e) => this.setState({ screenName: e.target.value }, this.getAllImages)}
              >
                <option value="">-- Select screen --</option> 
                <option value="default_screen">Default screen</option>
                <option value="default_screen_small">Default screen small</option>
                <option value="notification">Notification</option>
                <option value="iot_branding">IoT Branding</option>
              </Input>
            </FormGroup>
            <FormGroup>
              <Input type="file" multiple onChange={(event) => this.onChangeHandler(event)}>
                Select new photos
              </Input>
            </FormGroup>
            <FormGroup>
              <Button
                color={isUploading ? "secondary" : "primary"}
                disabled={isUploading}
                onClick={() => this.uploadImages()}
              >
                {isUploading ? 'Uploading...' : 'Upload new photos'}
              </Button>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          {fileList && fileList.length > 0 &&
            fileList.map((file, index) =>
              <Col xs="12" sm="4" md="3" lg="2" key={index}>
                <div className="pos-photos__img-container">
                  <img className="pos-photos__img" src={URL.createObjectURL(file)} alt={index} />
                  {/* <div className="pos-photos__img-delete">
                    <Button size="sm" color="danger" onClick={() => this.deleteImage(image)}>Delete</Button>
                  </div> */}
                </div>
              </Col>
            )
          }
        </Row>
        <Row>
          <Col xs="12" className="pos-photos__header">
            <h1>Current images</h1>
          </Col>
        </Row>
        <Row>
          {posImages.map(image => 
            <Col xs="12" sm="4" md="3" lg="2" key={image.image_id}>
              <div className="pos-photos__img-container">
                <img className="pos-photos__img" src={image.image_url} alt={image.image_url} />
                <div className="pos-photos__img-delete">
                  <Button size="sm" color="danger" onClick={() => this.deleteImage(image)}>Delete</Button>{' '}
                  <Button size="sm" color="info" onClick={() => this.copyImageLink(image)}>Copy link</Button>
                </div>
              </div>
            </Col>
          )}
        </Row>
      </div>
    );
  }
}