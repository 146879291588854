import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Button, FormGroup, Input, Label } from 'reactstrap';
import './index.css';
import api from '../../api';

export default class Segment extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            segment: {
                action: '',
                name: '',
                description: '',
                query: '',
                brand: 'tch',
            },
            editMode: this.props.segment.action === 'create' ? true : false,
            isSending: false,
            selectedTags: this.props.segment.tags || [],
        };
        if (this.props.editMode) {
            this.state.editMode = this.props.editMode;
        }
    }
    componentWillMount() {
        this.setState({ segment: this.props.segment });
    }
    getNewsFeedTags = () => {
        this.setState({ isSending: true });
        api.fetchAPI({
            method: 'get',
            uri: 'newsfeed/tags',
            success: (response) => {
                if (response.status === 'ok') {
                    this.setState({ tagList: response.data, isSending: false });
                }
                else {
                    alert(response.data.message);
                }
            },
            error: (error) => {
                this.setState({ isSending: false });
                alert(error)
            }
        });
    }
    selectTag = (tag_id) => {
        let { selectedTags, segment } = this.state;

        const idx = selectedTags.indexOf(tag_id);
        if (idx === -1) {
            selectedTags.push(tag_id);
        }
        else {
            selectedTags.splice(idx, 1);
        }

        segment.tags =  selectedTags;
        
        this.setState({ selectedTags, segment });
    }
    editSegment = (action_type) => {
        let editMode = false;
        const segment = this.state.segment;
        // Enable edit mode
        if (this.state.editMode === true) {
            editMode = false;
            segment.action = 'cancel';
        }
        else{
            editMode = true;
            segment.action = 'update';
            this.getNewsFeedTags();
        }

        this.setState({ editMode, segment })
    }
    deleteSegment = () => {
        //eslint-disable-next-line
        var r = confirm('Are you sure?');

        if (r === true) {
            api.fetchAPI({
                method: 'post',
                uri: `segment/${this.state.segment.segment_id}/delete`,
                body: this.state.segment,
                success: (response) => {
                    if (response.status === 'ok') {
                        const deleted_segment = this.state.segment;
                        deleted_segment.is_deleted = 1;
                        this.setState({ segment: deleted_segment });
                    }
                    else {
                        alert(response.data.message);
                    }
                },
                error: (error) => {
                    this.setState({ isSending: false });
                    alert(error)
                }
            });
        }
    }
    saveSegment = () => {
        let uri = '';
        let method = 'get';
        // If action is create
        if (this.state.segment.action === 'create') {
            uri = 'segment';
            method = 'post';
        }
        // If action is update
        else if (this.state.segment.action === 'update') {
            uri = `segment/${this.state.segment.segment_id}/update`;
            method = 'post';
        }
        // No action is provided, then GET only
        else if (!this.state.segment.action && this.state.segment.segment_id) {
            uri = `segment/${this.state.segment.segment_id}`;
            method = 'get';
        }
        else {
            uri = '';
            method = '';
        }
        this.setState({ isSending: true });
        api.fetchAPI({
            method: method,
            uri: uri,
            body: this.state.segment,
            success: (response) => {
                console.log(response)
                if (response.status === 'ok') {
                    const new_segment = this.state.segment
                    new_segment.segment_id = response.data.segment_id
                    this.setState({ editMode: false, isSending: false, segment: new_segment });
                }
                else {
                    alert(response.data.message);
                }
            },
            error: (error) => {
                this.setState({ isSending: false });
                alert(error)
            }
        });
    }
    editSegmentValue = ({ property, value }) => {
        const segment = this.state.segment;
        segment[property] = value ? value : '';
        this.setState({ segment });
        if (this.props.onEditSegmentPress) {
            this.props.onEditSegmentPress();
        }
    }
    render() {
        const { selectedTags, editMode } = this.state;
        return (
            <Col xs="12" sm={!editMode ? '6' : '12'} md={!editMode ? '4' : '12'}>
                {this.state.segment.is_deleted === 1 ?
                    <div />
                    :
                    <div className="segment">
                        {editMode === false ? 
                            <div>
                                <Row>
                                    <Col xs="12">
                                        <p>
                                            <span className={`segment--brand ${this.state.segment.brand === 'tch' ? 'segment--brand---tch' : 'segment--brand---tenren'}`}>{this.state.segment.brand}</span>
                                            {this.state.segment.bigquery_query ? <span className={`segment--brand segment--brand---bigquery`}>BigQuery</span> : null}
                                            <span><b>{this.state.segment.name}</b></span>
                                        </p>
                                    </Col>
                                    <Col xs="12">
                                        <p>{this.state.segment.description}</p>
                                    </Col>
                                    <Col xs="12">
                                        <p className="segment-query text-muted">
                                            
                                        </p>
                                    </Col>
                                </Row>
                                <Button size="sm" outline color="primary" className="segment-action" tag={Link} to={`/segment/${this.state.segment.segment_id}/actions`}>Actions </Button>
                                <Button size="sm" outline color="success" className="segment-action" tag={Link} to={`/segment/${this.state.segment.segment_id}/builder`}>Builder </Button>
                                <Button size="sm" outline color="info" className="segment-action" onClick={()=>this.editSegment('edit')}>Edit</Button>
                                <Button size="sm" outline color="danger" className="segment-action" onClick={()=>this.deleteSegment()}>Delete </Button>
                            </div>
                            :
                            <div>
                                <Row>
                                    <Col xs="12">
                                        <Label>Brand</Label>
                                        <Input
                                            type="select"
                                            value={this.state.segment.brand}
                                            onChange={(e) => this.editSegmentValue({ property: 'brand', value: e.target.value })}
                                        >
                                            <option value="">Select a brand</option>
                                            <option value="tch">TCH</option>
                                            <option value="tenren">Tenren</option>
                                        </Input>
                                    </Col>
                                    <Col xs="12">
                                        <FormGroup>
                                            <Label>Segment name</Label>
                                            <Input
                                                type="text"
                                                placeholder="Segment name..."
                                                value={this.state.segment.name}
                                                onChange={(e) => this.editSegmentValue({ property: 'name', value: e.target.value })}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col xs="12">
                                        <FormGroup>
                                            <Label>Slug name</Label>
                                            <Input
                                                type="text"
                                                placeholder="slug_name_to_sync_to_different_services"
                                                value={this.state.segment.slug_name}
                                                onChange={(e) => this.editSegmentValue({ property: 'slug_name', value: e.target.value })}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col xs="12">
                                        <FormGroup>
                                            <Label>Description</Label>
                                            <Input
                                                type="text"
                                                placeholder="Description..."
                                                value={this.state.segment.description ? this.state.segment.description : ''}
                                                onChange={(e) => this.editSegmentValue({ property: 'description', value: e.target.value })}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col xs="12">
                                        <FormGroup>
                                            <Label>Profile SQL</Label>
                                            <Input
                                                type="textarea"
                                                placeholder="Segment query..."
                                                row={2}
                                                value={this.state.segment.query ? this.state.segment.query : ''}
                                                onChange={(e) => this.editSegmentValue({ property: 'query', value: e.target.value })}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col xs="12">
                                        <FormGroup>
                                            <Label>BigQuery negate operator</Label>
                                            <Input
                                                type="textarea"
                                                placeholder="Negate operator for data return from BigQuery. Eg: NOT"
                                                row={2}
                                                value={this.state.segment.bigquery_negate_operator ? this.state.segment.bigquery_negate_operator : ''}
                                                onChange={(e) => this.editSegmentValue({ property: 'bigquery_negate_operator', value: e.target.value })}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col xs="12">
                                        <FormGroup>
                                            <Label>BigQuery SQL</Label>
                                            <Input
                                                type="textarea"
                                                placeholder="Segment BigQuery SQL... (SELECT app_user_id FROM...)"
                                                row={2}
                                                value={this.state.segment.bigquery_query ? this.state.segment.bigquery_query : ''}
                                                onChange={(e) => this.editSegmentValue({ property: 'bigquery_query', value: e.target.value })}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col xs="12">
                                        <FormGroup>
                                            <Label>Recommendation guide</Label>
                                            <Input
                                                type="textarea"
                                                placeholder="Recommendation guide"
                                                row={2}
                                                value={this.state.segment.recommendation_text ? this.state.segment.recommendation_text : null}
                                                onChange={(e) => this.editSegmentValue({ property: 'recommendation_text', value: e.target.value })}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col xs="12" sm="4">
                                        <FormGroup>
                                            <Label>Order number</Label>
                                            <Input
                                                type="number"
                                                placeholder="Order number"
                                                row={2}
                                                value={this.state.segment.order_number ? this.state.segment.order_number : 0}
                                                onChange={(e) => this.editSegmentValue({ property: 'order_number', value: e.target.value })}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs="12">
                                        <p>Newsfeed Tags (Ghost)</p>
                                        {this.state.tagList && this.state.tagList.length > 0 ? 
                                            this.state.tagList.map((tag) =>
                                                <Button
                                                    className="segment-ghost-tag-select"
                                                    key={tag.tag_id ? tag.tag_id : Math.random()}
                                                    color={selectedTags && selectedTags.indexOf(tag.tag_id) > -1 ? 'primary': 'default'}
                                                    size="sm"
                                                    onClick={() => this.selectTag(tag.tag_id)}
                                                >
                                                    {tag.name}
                                                </Button>
                                            )
                                            :
                                            <div />
                                        }
                                    </Col>
                                </Row>
                                <Button outline size="sm" color="success" disabled={this.state.isSending ? true : false} onClick={()=>this.saveSegment()}>Save</Button>
                                <Button outline size="sm" color="danger" className="segment-action" onClick={()=>this.editSegment('cancel')}>Cancel</Button>
                            </div>
                        }
                    </div>
                }
            </Col>
        );
    }
}