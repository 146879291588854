import React from 'react';
import Logo from './looping-logo.gif';
import './index.css';

export default class Loading extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentWillMount() {
    // this.setState({ action: this.props.action });
  }
  render() {
    return (
      <div className="loading-logo-container">
        <img className="loading-logo-looping" src={Logo} alt="Loading..." />
      </div>
    );
  }
}