import * as SRD from 'storm-react-diagrams';
import * as React from 'react';
import './index.css';
import api from '../../../api';

import { Button, Input, Row, Col, Label } from 'reactstrap';

function renderBuilder(props) {
    let selectedSegment = props.segment;
    let selectedAction = {action_id: 0};
    let conditionToAdd = '';
    let conditionActionId = 0;
    let intervalToWait = 0;
    let intervalTypeToWait = 'day';
    let segmentGraph = '';

    let engine = new SRD.DiagramEngine();
    let model = new SRD.DiagramModel();
    
    // Setup the diagram engine
    engine.installDefaultFactories();
    
    if (selectedSegment.graph && selectedSegment.graph !== '') {
        segmentGraph = JSON.parse((selectedSegment.graph));
        model.deSerializeDiagram(segmentGraph, engine);
    }

    engine.setDiagramModel(model);

    const saveModel = () => {
        const graph_json = JSON.stringify(model.serializeDiagram());
        selectedSegment.graph = graph_json;
        api.fetchAPI({
            method: 'post',
            uri: `segment/${selectedSegment.segment_id}/update`,
            body: selectedSegment,
            success: (response) => {
                if (response.status === 'ok') {
                    alert('Graph has been saved!');
                }
                else {
                    alert(response.data.message);
                }
            },
            error: (error) => {
                alert(error);
            }
        });
    }

    const addAction = (action, type = 'action') => {
        let node = {}
        if (type === 'action') {
            node = {
                title: action.name,
                color: 'rgb(0,192,255)',
                outPortName: 'Out',
                inPortName: 'In',
                position: {
                    x: 100,
                    y: 100,
                },
                extras: {
                    node_action_type: type,
                    action_id: action.action_id,
                }
            };
        }
        else if (type === 'start_node') {
            node = {
                title: 'Start',
                color: 'rgb(0,181,27)',
                outPortName: 'Out',
                inPortName: 'In',
                position: {
                    x: 100,
                    y: 100,
                },
                extras: {
                    node_action_type: type,
                    action_id: 0,
                }
            };
        }
        else if (type === 'delay') {
            node = {
                title: `Wait ${intervalToWait} ${intervalTypeToWait} from ${conditionActionId}`,
                color: 'rgb(234,234,234)',
                outPortName: 'Out',
                inPortName: 'In',
                position: {
                    x: 100,
                    y: 100,
                },
                extras: {
                    node_action_type: type,
                    delay_inteval_type: intervalTypeToWait,
                    delay_interval: intervalToWait,
                    action_id: conditionActionId,
                },
            };
        }
        else if (type === 'conditional') {
            node = {
                title: `${conditionToAdd} for ${conditionActionId}`,
                color: 'rgb(255,87,51)',
                truePortName: 'True',
                falsePortName: 'False',
                inPortName: 'In',
                position: {
                    x: 100,
                    y: 100,
                },
                extras: {
                    node_action_type: type,
                    action_id: conditionActionId,
                    condition_type: conditionToAdd,
                }
            };
        }
        
        var nodeDiagram = new SRD.DefaultNodeModel(node.title, node.color);
        
        nodeDiagram.addInPort(node.inPortName);
        if (node.truePortName && node.falsePortName) {
            nodeDiagram.addOutPort(node.truePortName);
            nodeDiagram.addOutPort(node.falsePortName);
        }
        else {
            nodeDiagram.addOutPort(node.outPortName);
        }

        nodeDiagram.setPosition(node.position.x, node.position.y);
        nodeDiagram.extras = node.extras;

        model.addAll(nodeDiagram);
        engine.setDiagramModel(model);
    }

    
    const selectActionToAdd = (input_action_id) => {
        if (input_action_id) {
            selectedSegment.actions.map((action) => {
                if (parseInt(action.action_id) === parseInt(input_action_id)) {
                    selectedAction = action;
                }
            });
        }
    }
    
    const selectConditionToAdd = (condition_type) => {
        conditionToAdd = condition_type;
    }

    const selectActionForCondition = (condition_action_id) => {
        conditionActionId = parseInt(condition_action_id);
    }

    const selectIntervalToWait = (interval_to_wait) => {
        intervalToWait = interval_to_wait;
    }

    const selectIntervalTypeWait = (interval_type_to_wait) => {
        intervalTypeToWait = interval_type_to_wait;
    }

    const testRun = () => {
        api.fetchAPI({
            method: 'get',
            uri: `segment/${selectedSegment.segment_id}/run_graph`,
            success: (response) => {
                if (response.status === 'ok') {
                    alert('Graph is now running')
                }
            },
            error: (error) => {
                alert(error)
            }
        });
    }

    return (
        <div className="diagram-container">
            <div className="diagram-controllers">
                <Row className="diagram-controller">
                    <Col xs="12">
                        <h5>Với 1 graph mới, bạn sẽ cần add Start node</h5>
                        <Button color="success" onClick={() => addAction(null, 'start_node')}>Add start node</Button>
                    </Col>
                </Row>
                <Row className="diagram-controller">
                    <Col xs="12">
                        <h5>Thêm 1 action</h5>
                        <div className="diagram-actions">
                            <Input
                                type="select"
                                onChange={(e) => selectActionToAdd(e.target.value)}
                            >
                                <option value="">-- Select an action to add --</option>
                                {selectedSegment.actions.map((action) => (
                                    <option value={action.action_id} key={`add_${action.action_id}`}>{action.name}</option>
                                ))}
                            </Input>
                            <div className="diagram-button">
                                <Button outline color="primary" onClick={() => addAction(selectedAction, 'action')}>Add action</Button>
                            </div>
                        </div>
                    </Col>
                    <Col xs="12">
                        <h5>Thêm 1 điều kiện</h5>
                        <div className="diagram-actions">
                            <Input
                                type="select"
                                onChange={(e) => selectConditionToAdd(e.target.value)}
                            >
                                <option value="">-- Select conditions --</option>
                                <option value="notification_read">Has read notification</option>
                                <option value="email_open">Has open email</option>
                                <option value="purchase_delivery_order">Has delivery order</option>
                                <option value="has_delivery_order_from_action">Has delivery order from action</option>
                            </Input>
                            <Input
                                type="select"
                                onChange={(e) => selectActionForCondition(e.target.value)}
                            >
                                <option value="">-- Select an action to apply condition --</option>
                                {selectedSegment.actions.map((action) => (
                                    <option value={action.action_id} key={action.action_id}>{action.name}</option>
                                ))}
                            </Input>
                            <div className="diagram-button">
                                <Button outline color="danger" onClick={() => addAction(null, 'conditional')}>Add condition</Button>
                            </div>
                        </div>
                    </Col>
                    <Col xs="12">
                        <h5>Thêm thời gian chờ</h5>
                        <div className="diagram-actions">
                            <Input
                                type="number"
                                onChange={(e) => selectIntervalToWait(e.target.value)}
                                placeholder="Nhập thời gian"
                            />
                            <Input
                                type="select"
                                onChange={(e) => selectIntervalTypeWait(e.target.value)}
                                placeholder="ngày tháng năm..."
                            >
                                <option value="">-- Select an action to apply wait time --</option>
                                <option value="hour">giờ</option>
                                <option value="minute">phút</option>
                                <option value="second">giây</option>
                                <option value="day">ngày</option>
                            </Input>
                            <Input
                                type="select"
                                onChange={(e) => selectActionForCondition(e.target.value)}
                            >
                                <option value="">-- Select an action to apply wait time --</option>
                                {selectedSegment.actions.map((action) => (
                                    <option value={action.action_id} key={action.action_id}>{action.name}</option>
                                ))}
                            </Input>
                            <Button outline color="primary" onClick={() => addAction(null, 'delay')}>Add wait time</Button>
                        </div>
                    </Col>
                </Row>
                <div className="diagram-actions-save">
                    <Button color="info" onClick={saveModel}>Save Graph</Button>
                    <Button color="warning" onClick={testRun}>Test run</Button>
                </div>
            </div>
            <SRD.DiagramWidget className="demo-canvas" diagramEngine={engine} />
        </div>
    );
};

export default renderBuilder;
