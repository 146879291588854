console.log(process.env.NODE_ENV)

const Constant = {
    apiBaseUrl: process.env.NODE_ENV === 'production' ? 'https://api.maria.tch.vn/api/' : 'http://127.0.0.1:5000/api/',
    tchAdminBaseUrl: process.env.NODE_ENV === 'production' ? 'https://admin.thecoffeehouse.com/api/' : 'https://admin.thecoffeehouse.com/api/',
    HRWConstant: {
        hrwBaseUrl: process.env.NODE_ENV === 'production' ? 'https://accounts.haravan.com/' : 'https://accounts.hara.vn/',
        clientId: process.env.NODE_ENV === 'production' ? 'a668424197e2d109271510a37905558e' : '36e46358e5d16d928eeb4c684e620496',
        clientSecret: process.env.NODE_ENV === 'production' ? 'e8a5c907d1c59e1d454c1fc902963a2f92b54be1e6ab324b7e041ead5384a29a' : 'b3a78fa70b8a62a748389564fe7ff9e74c73da934db63af1f3adcc7cccb3b907',
        accessTokenUri: process.env.NODE_ENV === 'production' ? 'https://accounts.haravan.com/connect/token' :'https://accounts.hara.vn/connect/token',
        authorizationUri: process.env.NODE_ENV === 'production' ? 'https://accounts.haravan.com/connect/authorize' : 'https://accounts.hara.vn/connect/authorize',
        redirectUri: process.env.NODE_ENV === 'production' ? 'https://maria.tch.vn/oauth_callback' : 'http://local.maria.tch.vn:3000/oauth_callback',
        scope_string: 'hr_api.read_emp_basic offline_access openid userinfo profile org',
        scopes: ['hr_api.read_emp_basic', 'offline_access', 'openid', 'userinfo', 'profile', 'org'],
        grant_type: 'authorization_code',
    },
}

export default Constant;