import React from 'react';

import Graph from './Graph';

import api from '../../api';
import normalizeSegment from '../../resources/normalizers/segment';

// The Roster component matches one of two different routes
// depending on the full pathname
export default class CampaignBuilder extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            segment: {
                segment_id: 0,
                actions: [],
            },
        }
    }
    componentDidMount = () => {
        document.title = "Campaign Builder | TCH Maria";
        this.getSegmentInfo();
    }
    getSegmentInfo = () => {
        api.fetchAPI({
            method: 'get',
            uri: `segment/${this.props.match.params.segment_id}?get_segment_actions=1`,
            success: (response) => {
                if (response.status === 'ok') {
                    this.setState({ segment: normalizeSegment(response.data) }, () => {
                        console.log(this.state.segment.actions[0]);
                    });
                }
            },
            error: (error) => {
                alert(error)
            }
        });
    }
    render() {
        return (
            <div>
                <h3>Graph for segment {this.state.segment.name}</h3>
                <div className="diagram-container">
                    <Graph segment={this.state.segment} />
                </div>
            </div>
        )
    };
}