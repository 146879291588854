import React from 'react';
import { Table, Button, Input } from 'reactstrap';
import DatePicker from 'react-datepicker';
import './index.css';
import Loading from '../../../components/Loading';
import api from '../../../api';

import "react-datepicker/dist/react-datepicker.css";

export default class ListPosts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      isUpdate: false,
      postId: null,
      data: {},
      datePicked: new Date()
    }
    this.onEditPost = this.onEditPost.bind(this)
    this.onUpdatePost = this.onUpdatePost.bind(this)
    this.onChangeData = this.onChangeData.bind(this)
    this.onChangeDate = this.onChangeDate.bind(this)
  }
  componentDidMount = () => {
    document.title = "Newsfeed Posts | TCH Maria";
    this.getPosts();
  }
  getPosts = () => {
    api.fetchAPI({
      method: 'get',
      uri: `newsfeed/posts`,
      success: (response) => {
        console.log(response)
        this.setState({
          posts: response.data,
          isLoading: false,
        });
      }
    });
  }

  onEditPost(e, postId) {
    let parentEle = document.getElementById(postId)
    let tdSelector = parentEle.querySelectorAll('td')

    let data = {}
    tdSelector.forEach(function (a, i) {
      if (i > 0 && i < (tdSelector.length - 1)) {
        let text = parentEle.getElementsByTagName('td')[i].textContent
        if (i === 0) {
          data.title = text
        }
        if (i === 1) {
          data.button = text
        }
        if (i === 2) {
          data.deeplink = text
        }
        if (i === 3) {
          data.effect = text
        }
      }
    })

    this.setState({
      isUpdate: !this.state.isUpdate,
      postId: postId,
      data: data
    })
  }

  onUpdatePost(postId) {
    api.fetchAPI({
      method: 'PUT',
      uri: 'newsfeed/post/' + postId,
      body: this.state.data,
      success: function (res) {
        console.log(res)
        if (res.status === 'ok') {
          alert('Cập nhật thành công')
          window.location.reload()
        }
      },
      error: function (err) {
        console.log(err)
      }
    })
  }

  onChangeData(e) {
    let keyObj = e.target.name
    let value = e.target.value
    this.setState(prevState => {
      let data = {...prevState.data}
      data[keyObj] = value
      return {data}
    })
  }

  onChangeDate(value) {
    this.setState(prevState => {
      let data = {...prevState.data}
      let dateFormat = new Date(Date.parse(value)).toISOString().slice(0,10)
      data['expired_at'] = dateFormat
      return {data}
    })
    this.setState({
      datePicked: new Date(Date.parse(value))
    })
  }

  render() {
    const { posts, isLoading } = this.state;
    if (!posts) return null;
    return (
      <div className="main">
        {isLoading ?
          <Loading />
          :
          <div className='ghost-cms'>
            <Button className="ghost-cms_nav_goBack" color="warning" onClick={() => {this.props.history.goBack()}}>Go back</Button>
            <Table striped bordered hover responsive>
              <thead>
                <tr>
                  <th>Title</th>
                  <th>Button label</th>
                  <th>Deeplink</th>
                  <th>Effect</th>
                  <th>Expired date</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                  {posts.map((post, index) => {
                    if (this.state.postId !== null && !this.state.isUpdate) {
                      return (
                        <tr key={index} id={post.id}>
                          <td>{post.title}</td>
                          <td>{post.button}</td>
                          <td>{post.deeplink}</td>
                          <td>{post.effect}</td>
                          <td>{post.expired_at ? post.expired_at : ''}</td>
                          <td className='action'>
                            <Button color='primary' onClick={((e) => this.onEditPost(e, post.id))}>
                              Edit
                            </Button>
                          </td>
                        </tr>
                      )
                    } else {
                      if (this.state.postId === post.id && this.state.isUpdate) { // Update post info
                        return (
                          <tr key={index} id={post.id}>
                            <td>{post.title}</td>
                            <td><Input name='button' value={this.state.data.button} onChange={this.onChangeData}/></td>
                            <td><Input name= 'deeplink' value={this.state.data.deeplink} onChange={this.onChangeData}/></td>
                            <td><Input name='effect' value={this.state.data.effect} onChange={this.onChangeData} /></td>
                            <td>{post.expired_at !== undefined ? 
                              <DatePicker selected={this.state.datePicked} onChange={this.onChangeDate} dateFormat="yyyy-MM-dd" /> : 
                              <DatePicker selected={this.state.datePicked} dateFormat="yyyy-MM-dd" onChange={this.onChangeDate}/>}
                            </td>
                            <td className='action'>
                              <Button color='success' onClick={((e) => this.onUpdatePost(post.id))}>Save</Button></td>
                          </tr>
                        )
                      } else {
                        return (
                          <tr key={index} id={post.id}>
                              <td>{post.title}</td>
                              <td>{post.button}</td>
                              <td>{post.deeplink}</td>
                              <td>{post.effect}</td>
                              <td>{post.expired_at}</td>
                              <td className='action'>
                                <Button color='primary' onClick={((e) => this.onEditPost(e, post.id))}>Edit</Button>
                              </td>
                          </tr>
                        )
                      }
                    }
                  })}
              </tbody>
            </Table>
          </div>
        }
      </div>
    );
  }
}